import Modal from '../Modal';
import styles from './DeleteModal.module.scss';
import Button from '../Button';
import Typography from '../Typography';

type Props = {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  onDelete: () => void;
  text: string;
  component?: React.ReactNode;
  confirmText?: string;
  buttonType?: 'danger' | 'gray' | 'green';
};

const DeleteModal = ({
  openModal,
  setOpenModal,
  onDelete,
  text,
  component,
  confirmText,
  buttonType = 'danger',
}: Props) => {
  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => setOpenModal(false)}
      onClose={() => setOpenModal(false)}
      className={styles.modal_wrapper}
    >
      <Typography variant="h2">Delete confirmation</Typography>
      <div className={styles.textWrapper}>
        <Typography className={styles.text} variant="p">
          {text}
        </Typography>
      </div>
      {component}
      <div className={styles.actionWrapper} style={{marginTop: 25}}>
        <Button
          className={styles.cancelButton}
          onClick={() => {
            setOpenModal(false);
          }}
          component="a"
          color="gray"
        >
          Cancel
        </Button>
        <Button onClick={onDelete} color={buttonType}>
          {confirmText || 'Delete'}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
