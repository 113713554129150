import cn from 'classnames';
import React from 'react';

import styles from './Typography.module.scss';

type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'label'
  | 'a'
  | 'strong';

interface Props extends React.HTMLAttributes<HTMLElement> {
  variant?: Variant;
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'a';

  textAlign?: 'left' | 'center' | 'right';
  href?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
}

function getClassName(variant?: Variant, hasHref?: boolean): Variant {
  return variant ? variant : hasHref ? 'a' : 'p';
}

function Typography({
  className,
  variant,
  component,
  children,
  textAlign = 'left',
  href,
  target,
  ...restProps
}: Props) {
  const Component = component || variant || 'p';
  const props = Component === 'a' ? {href, target, ...restProps} : restProps;

  return (
    <Component
      className={cn(
        styles[getClassName(variant, Boolean(href))],
        styles[`--align-${textAlign}`],
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
}

export default Typography;
