import {useMutation} from 'react-query';
import {CustomerService} from 'services/customer.service';

const useUpdateDeliveryAddress = () => {
  const {mutateAsync, isLoading} = useMutation({
    mutationFn: async ({
      requestId,
      addressId,
    }: {
      requestId: any;
      addressId: any;
    }) => {
      const customerService = new CustomerService();
      return customerService.updateDeliveryAddress(requestId, addressId);
    },
  });

  return {
    updateDeliveryAddress: mutateAsync,
    addressIsUpdating: isLoading,
  };
};

export default useUpdateDeliveryAddress;
