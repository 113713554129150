import {useEffect, useState} from 'react';
import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {useRouteMatch} from 'react-router-dom';
import {formatISO} from 'date-fns';
import {findLast, get} from 'lodash';

import api from 'api';
import {
  createAddress,
  MMScriptStatus,
  Note,
  RequestDetails,
  ScriptStatus,
  SelectOption,
} from 'types';
import {ScriptStatusOption} from 'components/ScriptStatusSelect';

import {data as dummyData} from './dummy';
import {getFullName} from 'helpers/user';
import useCurrentUser from 'hooks/useCurrentUser';
import {ActionMeta, SingleValue} from 'react-select';
import useCustomerInsurances from 'hooks/useCustomerInsurances';
import toast from 'react-hot-toast';
import useSendCustomerNotification from 'hooks/useSendCustomerNotification';
import useRequestSignature from 'hooks/useRequestSignature';
import useUserForm from 'pages/UserForm/useUserForm';
import useUpdateDeliveryAddress from './useUpdateDeliveryAddress';
import {useForm} from 'react-hook-form';
import {TypeGooglePlaceDetail} from 'components/GooglePlaceAutoComplete';

async function getRequestById({
  queryKey,
}: QueryFunctionContext): Promise<RequestDetails> {
  const [, id, order, status] = queryKey;
  const {data} = await api.get<RequestDetails>(`/requests/${id}/`, {
    params: {order, status},
  });
  return {...dummyData, ...data} as RequestDetails;
}

type FormData = Omit<createAddress, 'label'>;

function useRequestDetails() {
  const {params} = useRouteMatch<{id: string}>();
  const currentUser = useCurrentUser();
  const [showInsurance, setShowInsurance] = useState(false);
  const queryClient = useQueryClient();
  const {insurances} = useCustomerInsurances({});
  const {sendNotification} = useSendCustomerNotification();
  const {requestSignature} = useRequestSignature();
  const {addressIsUpdating, updateDeliveryAddress} = useUpdateDeliveryAddress();

  const [status, setStatus] = useState('');

  const queryCach = queryClient.getQueryCache().getAll();
  const [, , order, statuss] =
    (findLast(queryCach, (q) => q.queryKey[0] === 'requests')
      ?.queryKey as string[]) || [];

  const {data, isLoading, refetch} = useQuery(
    ['request_details', params.id, order, statuss],
    getRequestById,
    {
      refetchInterval:
        status === 'billed' || status === 'ready_for_delivery' ? 3000 : false,
    }
  );

  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [selectCardModal, setSelectCardModal] = useState(false);
  const [accessCardModal, setAccessCardModal] = useState(false);
  const [deliveryAddressId, setDeliveryAddressId] = useState(
    data?.delivery_address?.id
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [location, setLocation] = useState({
    latitude: 40.7596958,
    longitude: -73.9650045,
  });
  const [cardId, setCardIs] = useState(0);
  const [isMessageModalOpen, setMessageModalOpen] = useState(false);
  const [message, setMessage] = useState('');

  const {createNewAddress, cards} = useUserForm(data?.customer.id);
  const {control, setValue, handleSubmit, reset} = useForm<FormData>();

  useEffect(() => {
    if (cards.length > 0) {
      setCardIs(cards[0].custom_uuid);
    }
  }, [cards]);

  useEffect(() => {
    if (data?.request_origin.startsWith('app')) {
      if (data?.prescriptions.length) {
        setStatus(data.prescriptions[0].status);
      }
    } else {
      if (data?.otcs.length) {
        setStatus(data.otcs[0].status);
      }
    }
  }, [
    setStatus,
    data?.id,
    data?.otcs,
    data?.request_origin,
    data?.prescriptions,
  ]);

  const completeRequest = useMutation(
    'complete_request',
    async () => {
      await api.post(`/requests/${params.id}/complete/`);
      return;
    },
    {
      // onMutate: async () => {
      //   await queryClient.cancelQueries(['request_details', id]);

      //   const previousRequest = queryClient.getQueryData<RequestDetails>([
      //     'request_details',
      //     id,
      //   ]);

      //   if (!previousRequest) throw Error('Request is `undefined`');

      //   const nextRequest: RequestDetails = {
      //     ...previousRequest,
      //     status: 'completed',
      //   };

      //   queryClient.setQueryData(['request_details', id], nextRequest);

      //   return {previousRequest};
      // },
      onSuccess: () => {
        queryClient.invalidateQueries(['request_details']);
      },
    }
  );

  const updateScriptStatus = useMutation(
    'update_script_status',
    async ({
      drugId,
      status,
    }: {
      drugId: number;
      status: ScriptStatus | MMScriptStatus;
    }) => {
      const {data} = await api.post<{status: ScriptStatus}>(
        `/requests/${params.id}/prescriptions/${drugId}/status/`,
        {status}
      );
      return {...data, drugId};
    },
    {
      // onMutate: async ({ drugId, status }) => {
      //   await queryClient.cancelQueries(["request_details", id]);

      //   const previousRequest = queryClient.getQueryData<RequestDetails>([
      //     "request_details",
      //     id,
      //   ]);

      //   if (!previousRequest) throw Error("Request is `undefined`");

      //   const nextRequest: RequestDetails = {
      //     ...previousRequest,
      //     prescriptions: previousRequest.prescriptions.map((drug) =>
      //       drug.id === drugId ? { ...drug, status } : drug
      //     ),
      //   };

      //   queryClient.setQueryData(["request_details", id], nextRequest);

      //   return { previousRequest };
      // },
      onSettled: () => {
        queryClient.invalidateQueries(['request_details', params.id]);
      },
    }
  );

  const updatePreference = useMutation(
    'update_preference',
    async (datas: {
      preference_date: string | null;
      preference_time: string | null;
    }) => {
      const {data} = await api.patch<{
        preference_date: string;
        preference_time: string;
      }>(`/requests/${params.id}/`, datas);
      return {...data};
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['request_details', params.id]);
      },
    }
  );

  const createNote = useMutation(
    'create_note',
    async ({
      text,
      prescriber,
    }: {
      text: string;
      prescriber: boolean;
    }): Promise<string> => {
      const {data} = await api.post<{
        text: string;
        is_visible_to_prescribers: boolean;
      }>(`/requests/${params.id}/note/`, {
        text,
        is_visible_to_prescribers: prescriber,
      });
      return data.text;
    },
    {
      onMutate: async ({text, prescriber}) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(['request_details', params.id]);

        // Snapshot the previous value
        const previousRequest = queryClient.getQueryData<RequestDetails>([
          'request_details',
          params.id,
        ]);

        const newNote: Note = {
          id: Date.now(),
          text,
          is_visible_to_prescribers: prescriber,
          author: getFullName(currentUser),
          avatar: currentUser.avatar,
          created_at: formatISO(Date.now()),
        };

        const nextRequest: RequestDetails | undefined = previousRequest
          ? {
              ...previousRequest,
              notes: [newNote, ...previousRequest.notes],
            }
          : undefined;

        // Optimistically update to the new value
        queryClient.setQueryData(['request_details', params.id], nextRequest);

        // Return a context object with the snapshotted value
        return {previousRequest};
      },

      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, _text, context?: {previousRequest?: RequestDetails}) => {
        queryClient.setQueryData(
          ['request_details', params.id],
          context?.previousRequest
        );
      },

      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(['request_details', params.id]);
      },
    }
  );

  function onChangeTime(value: {
    preference_date: string | null;
    preference_time: string | null;
  }) {
    if (!data) return;

    updatePreference.mutate({
      ...value,
    });
  }

  function onChangeScriptStatus(
    newValue: SingleValue<ScriptStatusOption>,
    actionMeta: ActionMeta<SelectOption>
  ) {
    const drugId = Number(actionMeta.name?.split('-')[1]);
    if (newValue) {
      const status = newValue.value;
      updateScriptStatus.mutate({drugId, status});
    }
  }

  function onSubmitNote(text: string, prescriber: boolean) {
    return createNote.mutate({text, prescriber});
  }

  function complete() {
    completeRequest.mutateAsync();
  }

  function onStatusChange() {
    if (!data) {
      return;
    }
    api
      .patch(`/requests/${data.id}/`, {is_resolved: !data.is_resolved})
      .then(() => {
        refetch();
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.error_message || 'Something went wrong'
        );
      });
  }

  async function chargeCard(datas: any) {
    const data = await api.post<any>(`/payment/charge/`, datas);
    refetch();
    return data;
  }

  const handleNotifyCustomer = async () => {
    if (!data?.id) {
      return;
    }

    if (data.prescriptions.length > 0) {
      await sendNotification({
        requestId: data?.id,
        request_drugs:
          status === 'completed'
            ? data.prescriptions.filter((item) => item.status !== 'completed')
            : data.prescriptions,
      });
      refetch();
      toast.success('Notification sent successfully');
    }
  };

  const handleRequestSignature = async () => {
    if (!data?.id) {
      return;
    }
    await requestSignature({
      requestId: data?.id,
    });
    toast.success('Notification sent successfully');
  };

  const handleCreation = async (datas: createAddress) => {
    const res = await createNewAddress({
      ...datas,
      customer_id: data?.customer.id,
    });

    if (get(res, 'status') === 200) {
      setOpenAddressModal(false);
      setDeliveryAddressId(res.data.id);
      addressChangeHandler(res.data.id);
      reset();
      setLocation({
        latitude: 40.7596958,
        longitude: -73.9650045,
      });
    } else {
      toast.error('Something wrong with creation. Try again later !');
    }
  };

  const addressChangeHandler = async (id: number | string) => {
    updateDeliveryAddress({
      requestId: data?.id,
      addressId: id,
    })
      .then((res) => {
        setModalOpen(false);
      })
      .catch((er) => {
        const message = get(
          er,
          'response.data.error_message',
          "You can't change address!"
        );
        toast.error(message || "You can't change address!");
      });
  };

  const handleModalClose = () => setModalOpen(false);

  const submitSelectedCard = async () => {
    if (!cardId) {
      return;
    }
    try {
      await chargeCard({
        customer: data?.customer.id,
        payment_method_uuid: cardId,
        copay_items: get(data, 'prescriptions', []),
      });
      setAccessCardModal(false);
      setSelectCardModal(false);
      toast.success('Success');
    } catch (error) {
      toast.error('Error try again');
    }
  };

  const onCharge = async () => {
    if (cards.length < 1) {
      toast.error('Please add billing card to user first');
      return;
    }
    if (cards.length > 1) {
      setSelectCardModal(true);
      return;
    }
    setAccessCardModal(true);
  };

  const onSelectPlace = (placeDetail: TypeGooglePlaceDetail) => {
    setValue('latitude', placeDetail.lat);
    setValue('longitude', placeDetail.lon);
    setTimeout(() => {
      setLocation({latitude: placeDetail.lat, longitude: placeDetail.lon});
    }, 300);
    setValue('zipcode', placeDetail.zipcode);
    setValue('address_line_1', placeDetail.addressLine1);
    setValue('city', placeDetail.city);
    setValue('state', placeDetail.state);
  };

  function modalHandler() {
    setDeliveryAddressId(data?.delivery_address.id);
    setModalOpen(true);
  }

  const sendLeadToMeta = async (type: string) => {
    if (!data?.id) {
      return;
    }
    await api.post(`/requests/${data.id}/send-lead/${type}`);
    refetch();
    toast.success('Lead sent to Meta');
    refetch();
  };

  const sendMessage = async () => {
    if (!data?.id) {
      return;
    }
    try {
      await api.post(
        '/customers/sms/blast/',
        {
          message:
            message +
            '\n\n\n\n' +
            'Download our app for updates and refills' +
            '\n\n\n' +
            'Play Store: https://play.google.com/store/apps/details?id=com.medscustomerapp' +
            '\n\n' +
            'App Store: https://apps.apple.com/us/app/meds-rx-pharmacy-delivered/id1614593345',
          customer_ids: [data?.customer.id],
        },
        {
          params: {
            status: '',
          },
        }
      );
      toast.success('Message sent successfully');
      setMessageModalOpen(false);
      setMessage('');
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  return {
    data,
    id: params.id,
    showInsurance,
    insurances,
    isLoading,

    markDisabled: Boolean(
      data?.prescriptions.some(
        (drug) =>
          drug.status !== 'ready_for_delivery' &&
          drug.status !== 'cancel_script'
      )
    ),

    setShowInsurance,
    onSubmitNote,
    onChangeScriptStatus,
    complete,
    chargeCard,
    onChangeTime,
    refetch,
    onStatusChange,
    handleNotifyCustomer,
    handleRequestSignature,
    handleCreation,
    addressChangeHandler,
    cards,
    addressIsUpdating,
    handleModalClose,
    submitSelectedCard,
    onCharge,
    handleSubmit,
    control,
    setValue,
    onSelectPlace,
    location,
    openAddressModal,
    setOpenAddressModal,
    selectCardModal,
    setSelectCardModal,
    accessCardModal,
    setAccessCardModal,
    modalOpen,
    setModalOpen,
    deliveryAddressId,
    setDeliveryAddressId,
    cardId,
    setCardIs,
    status,
    setStatus,
    modalHandler,
    sendLeadToMeta,
    isMessageModalOpen,
    setMessageModalOpen,
    message,
    setMessage,
    sendMessage,
  };
}

export default useRequestDetails;
