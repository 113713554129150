import {useEffect, useRef, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {get, truncate} from 'lodash';
import cn from 'classnames';

import Avatar from 'components/Avatar';

import MenuIcon from './MenuIcon';
import UserModal from './UserModal';

import useAuth from 'hooks/useAuth';
import {getFullName} from 'helpers/user';
import useUi from 'hooks/useUi';
import {MenuItemName} from 'types';

import logo from './images/logo.svg';

import styles from './Sidebar.module.scss';
import MenuBar from './MenuIcon/MenuBar';

interface SubmenuItem {
  title: string;
  path: string;
  exact?: boolean;
}

interface MenuItem {
  name: MenuItemName;
  title: string;
  path: string;
  exact?: boolean;
  subitems?: SubmenuItem[];
}

function Sidebar() {
  const {currentUser} = useAuth();
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const {pathname} = useLocation();

  const [showTooltip, setShowTooltip] = useState(false);

  const {sidebarExtended, setSidebarExtended} = useUi();

  const MENU_ITEMS: MenuItem[] =
    get(currentUser, 'role') === 'prescriber'
      ? [
          {
            name: 'create_prescriptions',
            title: 'Prescribe',
            path: '/prescriber/prescriptions-create',
          },
          {
            name: 'prescriptions',
            title: 'Prescriptions',
            path: '/prescriber/prescriptions',
          },
          {
            name: 'users',
            title: 'Patients',
            path: '/prescriber/users',
          },
          {
            name: 'gear',
            title: 'Settings',
            path: '/prescriber/settings',
          },
        ]
      : [
          {name: 'requests', title: 'Requests', path: '/requests'},
          {name: 'rx', title: 'Orders', path: '/orders'},
          {
            name: 'truck',
            title: 'Dispatch',
            path: '/deliveries/delivery_planner',
            // subitems: [
            //   {title: 'Delivery planner', path: '/deliveries/delivery_planner'},
            //   //{title: 'All deliveries', path: '/deliveries', exact: true},
            //   {title: 'Instore pickups', path: '/deliveries/in-store_pickups'},
            // ],
          },
          {title: 'Patients', path: '/users', name: 'users'},
          {title: 'Prescribers', path: '/prescribers', name: 'prescribers'},

          {
            name: 'transactions',
            title: 'Transactions',
            path: '/transactions',
            exact: false,
          },
          {name: 'chat', title: 'Support', path: '/support'},
          {
            name: 'site',
            title: 'Website',
            path: '/website',
            subitems: [
              {title: 'Blogs', path: '/website/blogs'},
              {title: 'Pages', path: '/website/pages'},
            ],
          },
          {
            name: 'gear',
            title: 'Settings',
            path: '/settings',
            subitems: [
              // {title: 'Patients', path: '/settings/users'},
              {title: 'Products', path: '/settings/products'},
              {title: 'Employees', path: '/settings/employees'},
              {title: 'Vehicles', path: '/settings/vehicles'},
              // {title: 'Prescribers', path: '/settings/prescribers'},
              // {title: 'Routes', path: '/settings/routes'},
            ],
          },

          // {name: 'blog', title: 'Blogs', path: '/blogs'},
          // {name: 'pages', title: 'Pages', path: '/pages'},
        ];

  const text = useRef(MENU_ITEMS[0].title);
  const [top, setTop] = useState(0);

  useEffect(
    function () {
      setIsUserModalOpen(false);
    },
    [pathname]
  );

  return (
    <>
      <div className={styles.menu_top}>
        <div onClick={() => setSidebarExtended(!sidebarExtended)}>
          <MenuBar />
        </div>
        <img
          className={cn(styles.logo, styles.logoActive, styles.topLogo)}
          alt="meds logo"
          src={logo}
        />
      </div>
      <div
        style={{display: sidebarExtended ? 'none' : 'flex'}}
        className={styles.backdrop}
        onClick={() => setSidebarExtended(true)}
      />
      {showTooltip && sidebarExtended && (
        <span style={{top: top - 15}} className={styles.toolt}>
          {text.current}
        </span>
      )}
      <div className={cn(styles.root, {[styles['--narrow']]: sidebarExtended})}>
        <img
          onClick={() => setSidebarExtended(!sidebarExtended)}
          className={cn(styles.logo, !sidebarExtended && styles.logoActive)}
          alt="meds logo"
          src={logo}
        />
        <div className={styles.nav_padding} />
        <nav className={styles.nav}>
          <ul className={styles.menu}>
            {MENU_ITEMS.map((item, index) => (
              <li
                onClick={() => {
                  if (window.innerWidth <= 768) {
                    setSidebarExtended(true);
                  }
                }}
                onMouseMove={(event) => {
                  text.current = item.title;
                  setTop(event.clientY);
                  setShowTooltip(true);
                }}
                onMouseLeave={() => {
                  setShowTooltip(false);
                }}
                className={styles.menu_item}
                key={index}
              >
                <NavLink
                  to={item.path}
                  activeClassName={styles.active}
                  exact={item.exact}
                  style={{position: 'relative'}}
                >
                  <MenuIcon
                    className={styles.menu_item__icon}
                    name={item.name}
                  />
                  <span className={styles.text}>{item.title}</span>
                </NavLink>

                {item.subitems && (
                  <ul
                    className={cn(styles.submenu, {
                      [styles['--hidden']]: !pathname.includes(item.path),
                    })}
                  >
                    {item.subitems.map((subitem) => (
                      <li className={styles.submenu__item} key={subitem.path}>
                        <NavLink
                          to={subitem.path}
                          activeClassName={styles['--active']}
                          exact={subitem.exact}
                        >
                          {subitem.title}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
        <div className={styles.user_wrapper}>
          <div className={styles.user} onClick={() => setIsUserModalOpen(true)}>
            <div className={styles.user__details}>
              <span className={styles.user__name}>
                {getFullName(currentUser)}
              </span>
              <span className={styles.user__email}>
                {truncate(currentUser?.email, {length: 20, omission: '...'})}
              </span>
            </div>
            <Avatar
              className={styles.user__avatar}
              file={currentUser?.avatar}
            />
          </div>
        </div>
        <UserModal
          isOpen={isUserModalOpen}
          onClose={() => setIsUserModalOpen(false)}
        />
      </div>
    </>
  );
}

export default Sidebar;
