import Spinner from 'components/Spinner';
import CustomerDetails from './components/CustomerDetails';
import Header from './components/Header';
import Otcs from './components/Otcs';
import useRequestDetails from './useRequestDetails';
import styles from './RequestDetails.module.scss';
import {get} from 'lodash';
import {useHistory} from 'react-router-dom';
import SignedDocument from './components/SignedDocument';
import Button from 'components/Button';
import ConfirmModal from 'components/ConfirmModal';
import Typography from 'components/Typography';

// @ts-ignore
function RequestDetails() {
  const {id, data, confirmModal, setConfirmModal, refundHandler} =
    useRequestDetails();

  const history = useHistory();

  const viewOrder = (id: number) => {
    history.push(`/orders/${id}`);
  };

  if (!data) return <Spinner />;

  return (
    <div>
      <Header
        amount={get(data, 'amount', '0')}
        type={data?.transaction_origin}
        id={id}
        service_name={String(get(data, 'service_name', ''))}
        created_at={data?.transaction_date}
      />

      <div className={styles.content}>
        <div className={styles.main_content}>
          <CustomerDetails
            customer={get(data, 'customer', {})}
            deliveryData={get(data, 'delivery_address', {})}
          />
          <SignedDocument order={data} />
          {/* <DoctorDetails
            request={data}
            showInsurance={() => setShowInsurance(true)}
          /> */}
        </div>
        <div className={styles.drugs}>
          <Otcs
            drugs={data.list_rx}
            viewOrder={viewOrder}
            card_number={get(data, 'masked_card_number', '')}
          />
          {get(data, 'service_name') === 'CreditRefund' && (
            <div style={{marginTop: 20, display: 'flex', gap: 10}}>
              <Typography variant="h4">Refunded from: </Typography>
              <Typography
                onClick={() => {
                  history.push(
                    `/transactions/${get(data, 'original_transaction_id', '0')}`
                  );
                }}
                style={{textDecorationLine: 'underline', cursor: 'pointer'}}
                variant="h4"
              >
                {get(data, 'original_transaction_id', '0') || '0'}
              </Typography>
            </div>
          )}
          {get(data, 'service_name') === 'CreditSale' && (
            <Button
              disabled={get(data, 'is_refunded') === 'True'}
              onClick={() => setConfirmModal(true)}
              color="danger"
              style={{width: '100%', marginTop: 20}}
            >
              {get(data, 'is_refunded') === 'True'
                ? 'Already refunded'
                : 'Refund'}
            </Button>
          )}
        </div>
      </div>
      <ConfirmModal
        openModal={confirmModal}
        setOpenModal={setConfirmModal}
        onConfirm={refundHandler}
        text={`Are you sure, you want to refund this transaction?`}
      />
    </div>
  );
}

export default RequestDetails;
