import DataTable from 'components/DataTable';
import ListActionsWrapper from 'components/ListActionsWrapper';
import ListTabs from 'components/ListTabs';
import ListWrapper from 'components/ListWrapper';
import PageHeader from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import Typography from 'components/Typography';
import {useCallback, useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {DataItem} from 'types';
import calendar from '../../DeliveryPlanner/icons/calendar.svg';
import styles from '../Settings.module.scss';
import useRoutes, {ACTIVE_STATUSES} from './useRoutes';
import Pagination from 'components/Pagination';
import DateTimePicker from 'components/DateTimePicker';

const SCHEMA = [
  {
    dataKey: 'number',
    header: 'Route ID',
  },
  // {
  //   dataKey: 'nickname',
  //   header: 'Nickname',
  // },
  {
    dataKey: 'status',
    header: 'Route status',
    sortable: true,
  },
  {
    dataKey: 'created_at',
    header: 'Date & Time',
    sortable: true,
  },
  {
    dataKey: 'vehicle',
    header: 'Vehicle',
    sortable: true,
  },
  {
    dataKey: 'driver',
    header: 'Driver',
    sortable: true,
  },

  {
    dataKey: 'view',
    header: '',
  },
];

const tabs = [
  {title: 'All', value: ''},
  // {title: 'All', value: [ACTIVE_STATUSES, 'completed'].join(',')},
  {title: 'Active', value: ACTIVE_STATUSES},
  {title: 'Complete', value: 'completed'},
  {title: 'Canceled', value: 'cancelled'},
];

function Routes() {
  const {params} = useRouteMatch<{page: string | undefined}>();
  const [currentPage, setCurrentPage] = useState(parseInt(params.page || '1'));
  const {
    data,
    isLoading,
    activeTab,
    sortBy,
    sortOrder,
    setActiveTab,
    onSort,
    keyWord,
    setKeyWord,
    totalPages,
    pageSize,
    setPageSize,
    count,
    filter,
    setFilter,
  } = useRoutes({page: String(currentPage - 1)});
  const history = useHistory();

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params.page]);

  const handleViewDetails = useCallback(
    (item: DataItem) => {
      history.push(`/history/routes/${item.id}`);
    },
    [history]
  );

  const onchangeTab = useCallback(
    (tab: string) => {
      setActiveTab(tab);
      setCurrentPage(1);
      history.push(`/history/routes/page/1`);
    },
    [setActiveTab, history]
  );

  return (
    <div className={styles.main_container}>
      <PageHeader>
        <Typography variant="h2">Routes</Typography>
      </PageHeader>
      <ListWrapper>
        <ListTabs
          isSupportPage={true}
          tabs={tabs}
          activeTabValue={activeTab}
          onChange={onchangeTab}
        />
        <ListActionsWrapper className={styles.list_header}>
          <SearchInput
            value={keyWord}
            onChange={(val) => {
              setKeyWord(val.target.value);
              sessionStorage.setItem(`search_history`, val.target.value);
            }}
            placeholder="Search"
          />
          <div>
            {/* @ts-ignore */}
            <DateTimePicker
              icon={calendar}
              selectsRange={true}
              showTimeSelect={false}
              dateFormat="MM/dd/yy E"
              className={styles.date_created}
              startDate={filter.dateCreated?.startDate}
              endDate={filter.dateCreated?.endDate}
              placeholder="Created date"
              onChange={(data) => {
                setFilter((prev) => ({
                  ...prev,
                  dateCreated: {
                    startDate: data[0] || undefined,
                    endDate: data[1] || undefined,
                  },
                }));
              }}
            />
          </div>
        </ListActionsWrapper>
        <DataTable
          data={data}
          schema={SCHEMA}
          loading={isLoading}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onClick={handleViewDetails}
          onSort={onSort}
        />
        {!isLoading && (
          <Pagination
            pageSize={pageSize}
            count={count}
            setPageSize={setPageSize}
            basePath={'/history/routes'}
            page={currentPage}
            total={totalPages}
            setCurrentPage={setCurrentPage}
          />
        )}
      </ListWrapper>
    </div>
  );
}

export default Routes;
