import Spinner from 'components/Spinner';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import Note from 'components/Note';
import NoteForm from 'components/NoteForm';
import ImageView from 'components/ImageView';

import CustomerDetails from './components/CustomerDetails';
import DoctorDetails from './components/DoctorDetails';
import Header from './components/Header';
import Prescriptions from './components/Prescriptions';
import PreferenceTime from './components/PreferenceTime';
import Otcs from './components/Otcs';
import ListWrapper from 'components/ListWrapper';
import styles from './RequestDetails.module.scss';
import {Key, useMemo, useRef, useState} from 'react';
import Modal from '../../components/Modal';
import Typography from '../../components/Typography';
import {get} from 'lodash';
import Button from '../../components/Button';
import FormGroup from 'components/FormGroup';
import {Controller} from 'react-hook-form';
import {GooglePlaceAutoComplete} from 'components/GooglePlaceAutoComplete';
import TextField from 'components/TextField';
import {MMSubStatus, SupportRequestStatus} from 'types';
import ArrowIcon from 'pages/UserForm/UserAddresses/ArrowIcon';
import MapPreview from 'components/MapPreview';
import useAuth from 'hooks/useAuth';
import {useReactToPrint} from 'react-to-print';
import PrintIcon from 'icons/PrintIcon';
import RefillHandler, {
  RefillProps,
} from 'pages/PrescriberPrescriptions/RefillHandler';
import SupportStatusSelect from 'pages/Support/SupportStatusSelect';
import '../../../node_modules/react-linechart/dist/styles.css';
import {Bar} from 'react-chartjs-2';

import {
  CategoryScale,
  Chart,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
} from 'chart.js';
import useRequestDetails from './useRequestDetails';
import {format, parseISO} from 'date-fns';
import Select from 'components/Select';
import FormActionGroup from 'components/FormActionGroup';

Chart.register(
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip
);

const returnTime = (value: string) => {
  if (Number(value) < 60) {
    return `${Math.round(Number(value))} sec`;
  }
  if (Number(value) < 3600) {
    return `${Math.round(Number(value) / 60)} min`;
  }
  return `${Math.round(Number(value) / 3600)} hr`;
};

const statuses = {
  completed: 'Unbilled',
  billed: 'Billed',
  filled: 'Filled',
  otc_status: 'OTC CheckOut',
  waiting_for_pickup: 'Waiting for Pickup',
  ready_for_delivery: 'Ready for Delivery',
  delivery_partner: 'Delivery Partner',
  picked_up: 'Picked-Up',
  delivered: 'Delivered',
  cancel_script: 'Canceled',
  archive: 'Archived',
};

const colors = {
  new: '#eeeeee',
  in_progress: '#eeeeee',
  invalid: '#eeeeee',
  completed: '#eeeeee',
  waiting_for_pickup: '#f5e274',
  billed: '#d8f5c8',
  paid: '#d6b6fb',
  filled: '#d6b6fb',
  otc_status: '#eeeeee',
  delivered: '#6ECF97',
  ready_for_delivery: '#f5e274',
  cancel_script: '#f9dde5',
  picked_up: '#a8f574',
  delivery_partner: '#d6b6fb',
  archive: '#eeeeee',
};

export const origin = {
  app: 'app',
  micromerchant: 'micromerchant',
  landing_page: 'app',
  dashboard: 'micromerchant',
  meta_ads: 'app',
  'app refill': 'app',
};

const leadStatuses = {
  Lead: 'Lead (form signup)',
  Contact: 'Contact (spoke with them)',
  Subscribe: 'Subscribe (newsletter)',
  Purchase: 'Purchase (order)',
};

// @ts-ignore
function RequestDetails() {
  const {currentUser} = useAuth();
  const [isPrescriber] = useState(currentUser?.role === 'prescriber');

  const {
    id,
    showInsurance,
    data,
    markDisabled,
    setShowInsurance,
    onChangeScriptStatus,
    onSubmitNote,
    complete,
    onChangeTime,
    refetch,
    onStatusChange,
    handleNotifyCustomer,
    handleRequestSignature,
    handleCreation,
    addressChangeHandler,
    cards,
    addressIsUpdating,
    handleModalClose,
    submitSelectedCard,
    onCharge,
    control,
    setValue,
    handleSubmit,
    onSelectPlace,
    location,
    modalOpen,
    openAddressModal,
    setOpenAddressModal,
    selectCardModal,
    setSelectCardModal,
    accessCardModal,
    setAccessCardModal,
    deliveryAddressId,
    setDeliveryAddressId,
    cardId,
    setCardIs,
    status,
    setStatus,
    modalHandler,
    sendLeadToMeta,
    isMessageModalOpen,
    setMessageModalOpen,
    message,
    setMessage,
    sendMessage,
  } = useRequestDetails();
  const [showKits, setShowKits] = useState(false);

  const printRef = useRef<HTMLDivElement>(null);
  const refillRef = useRef<RefillProps>(null);
  const reactToPrintFn = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => setShowKits(false),
    pageStyle: '@page { size: auto; margin: 15mm; }',
  });

  const selectedCard = cards.find((item) => item.custom_uuid === cardId);

  const chartDatas = useMemo(() => {
    if (!data?.prescriptions) {
      return [];
    }

    const times: {
      label: string;
      value: number;
      status: MMSubStatus;
      date: string;
    }[] = [];
    const dates = get(data, 'status_times', []);
    if (dates) {
      dates.forEach((item) => {
        times.push({
          label: statuses[item.status as keyof typeof statuses],
          status: item.status,
          value: Number(item.duration),
          date: format(parseISO(item.date), 'MM/dd hh:mm a'),
        });
      });
    }
    return times;
  }, [data]);

  if (!data)
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <div>
      <Header
        id={id}
        created_at={data.created_at}
        status={data.status}
        app={data.request_origin}
        is_resolved={data.is_resolved}
        completeDisabled={markDisabled}
        complete={complete}
        rx_no={
          data.prescriptions.length > 0
            ? get(data.prescriptions[0], 'rx_no', 0)
            : data.otcs.length > 0
            ? get(data.otcs[0], 'rx_no', 0)
            : 0
        }
        previous_id={data.previous_id}
        next_id={data.next_id}
      />
      {(get(data.prescriptions, '[0].meds_kit', []) || []).length > 0 &&
        isPrescriber && (
          <div
            style={{
              position: 'absolute',
              top: 30,
              right: 20,
              display: 'flex',
              gap: 10,
            }}
          >
            <Button
              onClick={() =>
                refillRef.current?.open(get(data.prescriptions, '[0].id', 0))
              }
              color="blue"
            >
              Refill
            </Button>
            <Button
              onClick={() => {
                setShowKits(true);
                setTimeout(reactToPrintFn, 1000);
              }}
              color="blue"
            >
              <PrintIcon size={16} />
            </Button>
          </div>
        )}

      <div
        ref={printRef}
        style={{
          flexDirection: showKits ? 'column' : 'row',
          gap: showKits ? 20 : 0,
        }}
        className={styles.content}
      >
        <div className={styles.main_content}>
          <CustomerDetails
            pharmacy={data.pharmacy}
            customer={data.customer}
            isOrder={
              origin[data.request_origin as keyof typeof origin] !== 'app'
            }
            deliveryData={data?.delivery_address}
            modalHandler={modalHandler}
            allergies={String(get(data, 'customer.allergies', '') || '')}
          />
          {!showKits && (
            <DoctorDetails
              request={data}
              showInsurance={() => setShowInsurance(true)}
            />
          )}

          <Card>
            <CardContent dark>
              <NoteForm
                source={origin[data.request_origin as keyof typeof origin]}
                isPrescriber={isPrescriber}
                onSubmit={onSubmitNote}
              />
              {data.notes.map((note, index) => (
                <Note
                  key={note.id}
                  {...note}
                  text={note.text}
                  isCompleted={
                    get(data, 'status', '') === 'completed' &&
                    index === 0 &&
                    !!get(data, 'route_id', null)
                  }
                  order_id={data.id}
                  route_id={Number(get(data, 'route_id', null))}
                />
              ))}
            </CardContent>
          </Card>
        </div>
        <div className={styles.drugs}>
          <Button
            style={{
              width: '100%',
              marginBottom: 15,
            }}
            color="blue"
            onClick={() => setMessageModalOpen(true)}
          >
            SMS to Patient
          </Button>
          {origin[data.request_origin as keyof typeof origin] === 'app' &&
            data.status === 'call_doctor' && (
              <Card>
                <CardContent
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  pt="sm"
                >
                  <p>Status:</p>
                  <SupportStatusSelect
                    name={`support-request-${data.id}`}
                    status={
                      SupportRequestStatus[
                        data.is_resolved ? 'ARCHIVED' : 'ACTIVE'
                      ]
                    }
                    onChange={onStatusChange}
                  />
                </CardContent>
              </Card>
            )}
          {data.prescriptions.length > 0 ? (
            <Prescriptions
              data={data}
              showKits={showKits}
              isPrescriber={isPrescriber}
              requestId={data.id}
              origin={origin[data.request_origin as keyof typeof origin]}
              drugs={data.prescriptions}
              status={status}
              onChangeStatus={(newVal, action) => {
                setStatus(get(newVal, 'value', ''));
                onChangeScriptStatus(newVal, action);
              }}
              sync_time={data.sync_time as string}
              sync_source={data.sync_source}
              onCharge={onCharge}
              onSendNotification={handleNotifyCustomer}
              onRequestSignature={handleRequestSignature}
            />
          ) : (
            ''
          )}
          <Otcs
            requestId={data.id}
            drugs={data.otcs}
            origin={data.only_otc ? 'micromerchant' : data.request_origin}
            onChangeStatus={onChangeScriptStatus}
          />
          {/* {data.sync_source === 'app' && data.request_origin === 'meta_ads' && (
            <Button onClick={sendLeadToMeta} className={styles.metaButton}>
              Send lead to Meta
            </Button>
          )} */}
          {data.sync_source === 'app' && data.request_origin === 'meta_ads' && (
            <>
              <Typography
                style={{
                  marginTop: 20,
                  marginBottom: 4,
                  fontSize: 16,
                }}
                variant="p"
              >
                Lead Status
              </Typography>
              <Select
                onChange={(val) => {
                  sendLeadToMeta(String(val?.value));
                }}
                placeholder="Select Event Type"
                options={Object.entries(leadStatuses).map(([key, value]) => ({
                  value: key,
                  label: value,
                }))}
                value={
                  get(data, 'meta_event_type')
                    ? {
                        value: String(get(data, 'meta_event_type', '')),
                        label:
                          leadStatuses[
                            get(
                              data,
                              'meta_event_type',
                              ''
                            ) as keyof typeof leadStatuses
                          ],
                      }
                    : undefined
                }
              />
            </>
          )}
          {origin[data.request_origin as keyof typeof origin] !== 'app' &&
            !isPrescriber && (
              <PreferenceTime
                onChangeTime={onChangeTime}
                preference_time={data.preference_time}
                preference_date={data.preference_date}
              />
            )}
          {data.prescriptions.length > 0 &&
            origin[data.request_origin as keyof typeof origin] !== 'app' &&
            get(data.prescriptions, '[0].status_dates', []).length > 0 && (
              <>
                {/* <Typography
                  style={{marginTop: 20, marginBottom: 20}}
                  component="p"
                  variant="h4"
                >
                  Processing time:
                </Typography> */}
                <div
                  style={{
                    border: '1px solid #e0e0e0',
                    padding: 10,
                    marginTop: 20,
                    borderRadius: 8,
                    backgroundColor: '#fff',
                  }}
                >
                  <Typography
                    style={{
                      marginBottom: 20,
                    }}
                    variant="h4"
                  >
                    Status Durations
                  </Typography>
                  <div style={{display: 'flex'}}>
                    <div>
                      <Bar
                        height={250}
                        options={{
                          plugins: {
                            tooltip: {
                              enabled: true,
                              callbacks: {
                                label: (context) => {
                                  return chartDatas[context.dataIndex].date;
                                },
                              },
                            },
                          },
                          scales: {
                            y: {
                              ticks: {
                                callback: (value) => {
                                  return returnTime(String(value));
                                  // return chartDatas.some(
                                  //   (item) => item.value === value
                                  // )
                                  //   ? value
                                  //   : '';
                                },
                              },
                              max:
                                Math.max(
                                  ...chartDatas.map((item) => item.value)
                                ) + 2,
                              suggestedMax: Math.max(
                                ...chartDatas.map((item) => item.value)
                              ),
                            },
                          },
                        }}
                        data={{
                          labels: chartDatas.map((item) => item.label),
                          datasets: [
                            {
                              data: chartDatas.map((item) => item.value),
                              label: '',
                              backgroundColor: chartDatas.map(
                                (item) => colors[item.status]
                              ),
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: 10,
                    marginTop: 20,
                  }}
                >
                  <Typography style={{fontSize: 16}} variant="p">
                    Total processing time:
                  </Typography>
                  <b>
                    <Typography style={{fontSize: 16}} variant="p">
                      {get(data.prescriptions, '[0].total_processing_time', '')}
                    </Typography>
                  </b>
                </div>
              </>
            )}
        </div>
      </div>
      <RefillHandler refetch={refetch} ref={refillRef} />
      <Modal
        isOpen={modalOpen}
        onClose={handleModalClose}
        className={styles.list_wrapper}
      >
        <Typography component="p" className={styles.modalDesc} variant="h2">
          Select Delivery Address:
        </Typography>
        <div className={styles.optionWrapper}>
          {get(data.customer, 'delivery_addresses', []).map(
            (item: any, index: Key | null | undefined) => {
              return (
                <label key={index} className={styles.container}>
                  <input
                    type="radio"
                    name="radio"
                    defaultChecked={deliveryAddressId === item.id}
                    onClick={() => setDeliveryAddressId(item.id)}
                  />
                  <span className={styles.radioButtonText}>
                    {get(item, 'address_line_1', '')}, {get(item, 'city', '')},{' '}
                    {get(item, 'state', '')}, {get(item, 'zipcode', '')}
                    {get(item, 'unit_address', '')
                      ? `, ${get(item, 'unit_address', '')}`
                      : ''}
                  </span>
                  {!get(item, 'is_valid', true) && (
                    <span className={styles.wrongAddress}>issue</span>
                  )}
                </label>
              );
            }
          )}
          <div
            onClick={() => setOpenAddressModal((pr) => !pr)}
            className={styles.addAddress}
          >
            <p>+ Add address</p>
            <ArrowIcon
              className={
                openAddressModal ? styles.icon_rotated : styles.arror_icon
              }
            />
          </div>
          {openAddressModal && (
            <ListWrapper
              style={{
                marginTop: 10,
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 15,
              }}
            >
              {/* <Typography component="p" variant="h4">
                Add New Address:
              </Typography> */}
              <FormGroup>
                <Controller
                  name="city"
                  control={control}
                  rules={{required: 'Please, Select the address'}}
                  render={({field, fieldState}) => (
                    <GooglePlaceAutoComplete
                      label="Your address"
                      placeholder="Address"
                      onSelectPlace={onSelectPlace}
                      error={fieldState.error?.message}
                      options={{
                        types: ['address'],
                      }}
                    />
                  )}
                />
                <Controller
                  name="unit_address"
                  control={control}
                  render={({field, fieldState}) => (
                    <TextField
                      label="Unit number"
                      wrapperClass={styles.googleInput}
                      placeholder="Enter unit number"
                      hasError={false}
                      onChange={field.onChange}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </FormGroup>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: 20,
                }}
              >
                {!!location.latitude && (
                  <MapPreview
                    zoom={15}
                    onDrag={(location) => {
                      setValue('latitude', location.lat);
                      setValue('longitude', location.lon);
                    }}
                    center={[location.latitude, location.longitude]}
                  />
                )}
              </div>

              <div className={styles.actionWrapper}>
                {/* <Button
                  className={styles.cancelButton}
                  onClick={() => {
                    setOpenAddressModal(false);
                  }}
                  component="a"
                  color="gray"
                >
                  Cancel
                </Button> */}
                <Button
                  type="submit"
                  onClick={handleSubmit(handleCreation)}
                  color="green"
                >
                  Add
                </Button>
              </div>
            </ListWrapper>
          )}
        </div>
        <div className={styles.actions}>
          <Button color="outlined" size="md" onClick={handleModalClose}>
            Close
          </Button>
          <Button
            type="submit"
            color="green"
            onClick={() => addressChangeHandler(deliveryAddressId || 0)}
            size="md"
            loading={addressIsUpdating}
          >
            Submit
          </Button>
        </div>
      </Modal>
      {data.insurance &&
        data.insurance.front_image &&
        data.insurance.back_image && (
          <ImageView
            show={showInsurance}
            onClose={() => setShowInsurance(false)}
            images={[
              data.insurance.front_image.file,
              data.insurance.back_image.file,
            ]}
          />
        )}
      {/* <Modal
        isOpen={false}
        onClose={() => setOpenAddressModal(false)}
        className={styles.list_wrapper}
      >
        <div className={styles.modalWrapper}>
          <FormGroup>
            <Controller
              name="city"
              control={control}
              rules={{required: 'Please, Select the address'}}
              render={({field, fieldState}) => (
                <GooglePlaceAutoComplete
                  label="Your address"
                  placeholder="Address"
                  onSelectPlace={onSelectPlace}
                  error={fieldState.error?.message}
                  options={{
                    types: ['address'],
                  }}
                />
              )}
            />
            <Controller
              name="unit_address"
              control={control}
              render={({field, fieldState}) => (
                <TextField
                  label="Unit number"
                  wrapperClass={styles.googleInput}
                  placeholder="Enter unit number"
                  hasError={false}
                  onChange={field.onChange}
                  error={fieldState.error?.message}
                />
              )}
            />
          </FormGroup>

          <div className={styles.actionWrapper}>
            <Button
              className={styles.cancelButton}
              onClick={() => {
                setOpenAddressModal(false);
              }}
              component="a"
              color="gray"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit(handleCreation)}
              color="green"
            >
              Add
            </Button>
          </div>
        </div>
      </Modal> */}
      <Modal
        isOpen={selectCardModal}
        onClose={() => setSelectCardModal(false)}
        className={styles.list_wrapper}
      >
        <Typography component="p" className={styles.modalDesc} variant="h2">
          Select Card to Charge:
        </Typography>
        <div className={styles.optionWrapper}>
          {cards.map((item: any, index: Key | null | undefined) => {
            return (
              <label key={index} className={styles.radioOption}>
                <input
                  type="radio"
                  name="radio"
                  defaultChecked={cardId === item.custom_uuid}
                  onClick={() => setCardIs(item.custom_uuid)}
                />
                {/* <div className={styles.column}> */}
                <span className={styles.radioButtonText}>
                  {get(item, 'cardholder_name', '')}
                </span>
                <p
                  style={{marginLeft: 'auto'}}
                  className={styles.radioButtonText}
                >
                  **** **** **** {get(item, 'last4', '')}
                </p>
                {/* </div> */}
              </label>
            );
          })}
        </div>
        <div className={styles.actions}>
          <Button
            color="outlined"
            size="md"
            onClick={() => setSelectCardModal(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            color="green"
            onClick={() => setAccessCardModal(true)}
            size="md"
            loading={addressIsUpdating}
          >
            Submit
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={accessCardModal}
        onClose={() => setAccessCardModal(false)}
        className={styles.list_wrapper}
      >
        <Typography component="p" className={styles.modalDesc} variant="h2">
          Are you sure you want to charge this card?
        </Typography>
        <div className={styles.card}>
          <div className={styles.item_header}>
            <div>{get(selectedCard, 'cardholder_name', '')}</div>
          </div>
          <div className={styles.row}>
            <div>**** **** **** {get(selectedCard, 'last4', '')}</div>
            <div>
              Expiration: {get(selectedCard, 'exp_month', '')}/
              {get(selectedCard, 'exp_year', '')}
            </div>
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            color="outlined"
            size="md"
            onClick={() => setAccessCardModal(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            color="green"
            onClick={submitSelectedCard}
            size="md"
            loading={addressIsUpdating}
          >
            Submit
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={isMessageModalOpen}
        onClose={() => {
          setMessageModalOpen(false);
          setMessage('');
        }}
        onRequestClose={() => {
          setMessageModalOpen(false);
          setMessage('');
        }}
        className={styles.smsModal}
      >
        <Typography variant="h2">
          Send message to {get(data, 'customer.first_name', '')}{' '}
          {get(data, 'customer.last_name', '')}
        </Typography>
        <textarea
          className={styles.messageTextarea}
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <FormActionGroup className={styles.buttonWrap}>
          <Button onClick={sendMessage} color="blue">
            Send
          </Button>
        </FormActionGroup>
      </Modal>
    </div>
  );
}

export default RequestDetails;
