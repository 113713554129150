import {useCallback, useEffect, useMemo, useState} from 'react';
import {format, parseISO} from 'date-fns';
import {QueryFunctionContext, useQuery, useQueryClient} from 'react-query';

import Typography from 'components/Typography';

import {DataItem, ListResponse, SupportRequest} from 'types';
import useSort from 'hooks/useSort';
import api from 'api';
import {DATETIME_FORMAT} from 'constants/datetime';
import SupportStatusSelect from './SupportStatusSelect';
import useDebounce from 'hooks/useDebounce';

async function getSupportRequests({queryKey}: QueryFunctionContext<any[]>) {
  const [, page, search, status, pageSize, filter] = queryKey;
  const {data} = await api.get<ListResponse<SupportRequest>>('/support/', {
    params: {
      page,
      offset: +page * +pageSize,
      limit: +pageSize,
      search,
      status,
      created_at_before: filter.dateCreated?.endDate
        ? format(filter.dateCreated?.endDate, 'yyyy-MM-dd')
        : undefined,
      created_at_after: filter.dateCreated?.startDate
        ? format(filter.dateCreated?.startDate, 'yyyy-MM-dd')
        : undefined,
    },
  });
  return data;
}

async function updateSupportRequestStatus(
  id: number,
  valueOption: {value: string}
) {
  await api.patch(`/support/${id}/`, {status: valueOption.value});
}

const transformData =
  (onStatusChange: any) =>
  (item: SupportRequest): DataItem => {
    return {
      ...item,
      number: <Typography variant="strong">#{item.id}</Typography>,
      new_leaf_order_number: item.new_leaf_order_number && (
        <Typography variant="strong">#{item.new_leaf_order_number}</Typography>
      ),
      status: (
        <SupportStatusSelect
          name={`support-request-${item.id}`}
          status={item.status}
          onChange={onStatusChange(item)}
        />
      ),
      created_at: format(parseISO(item.created_at), DATETIME_FORMAT),
    };
  };

function useSupport({page, search}: {page: number; search: string}) {
  const [status, setStatus] = useState('active');
  const [pageSize, setPageSize] = useState(40);
  const [filter, setFilter] = useState({
    dateCreated: {
      startDate: sessionStorage.getItem('date_created_start')
        ? new Date(
            JSON.parse(sessionStorage.getItem('date_created_start') || '')
          )
        : null,
      endDate: sessionStorage.getItem('date_created_end')
        ? new Date(JSON.parse(sessionStorage.getItem('date_created_end') || ''))
        : null,
    },
  });
  const queryClient = useQueryClient();
  const {sortBy, sortOrder, onSort} = useSort('created_at');

  useEffect(() => {
    sessionStorage.setItem(
      'date_created_start',
      filter.dateCreated?.startDate
        ? JSON.stringify(filter.dateCreated?.startDate)
        : ''
    );
    sessionStorage.setItem(
      'date_created_end',
      filter.dateCreated?.endDate
        ? JSON.stringify(filter.dateCreated?.endDate)
        : ''
    );
  }, [filter.dateCreated?.startDate, filter.dateCreated?.endDate]);

  const handleUpdateStatus = useCallback(
    (item: SupportRequest) => async (option: {value: string}) => {
      await updateSupportRequestStatus(item.id, option);
      queryClient.invalidateQueries(['support_requests']);
    },
    [queryClient]
  );

  const searchDebounced = useDebounce(search, 400);

  const {data, isLoading} = useQuery(
    [
      'support_requests',
      String(page),
      searchDebounced,
      status,
      String(pageSize),
      filter,
    ],
    getSupportRequests
  );
  const supportRequests = useMemo(
    function () {
      return data?.results.map(transformData(handleUpdateStatus)) || [];
    },
    [data?.results, handleUpdateStatus]
  );
  return {
    data: supportRequests,
    totalPages:
      data?.count && data.limit ? Math.ceil(data.count / data.limit) : 0,
    isLoading,
    status,
    sortBy,
    sortOrder,
    setStatus,
    onSort,
    onUpdateSupportRequestStatus: handleUpdateStatus,
    pageSize,
    count: data?.count || 0,
    setPageSize,
    filter,
    setFilter,
  };
}

export default useSupport;
