import {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {useQuery, QueryFunctionContext} from 'react-query';

import {
  DataItem,
  ListResponse,
  PaginatedResponse,
  Upload,
  User,
  Vehicle,
} from 'types';

import api from 'api';
import roles from 'constants/roles';
import states from 'constants/states';
import {get} from 'lodash';
import useDeleteEmployee from './useDeleteEmployee';
import toast from 'react-hot-toast';
import {phoneFormat} from 'helpers/phoneFormat';
import useAuth from 'hooks/useAuth';

interface Option {
  label: string;
  value: string | number;
}

interface FormValue {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  organisation: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  zipcode: string;
  state: string;
  npi_no: string;
  username: string;
  password: string;
  password_confirmation: string;
  latitude: number;
  longitude: number;
  role: Option;
  vehicle?: Option;
  is_active: boolean;
  license_front: Upload;
  license_back: Upload;
  document_number?: string;
  document_state?: Option;
  issue_state?: Option;
}

async function getPrescriptions({queryKey}: QueryFunctionContext<any[]>) {
  const [, userId, page, pageSize] = queryKey;

  if (!userId) return undefined;

  const {data} = await api.get<PaginatedResponse<any>>(`/requests/`, {
    params: {
      offset: +page * Number(pageSize),
      limit: Number(pageSize),
      prescriber_user_id: userId,
    },
  });

  return data;
}

async function getCustomers({queryKey}: QueryFunctionContext<any[]>) {
  const [, userId, page, pageSize] = queryKey;
  const {data} = await api.get<ListResponse<any>>(`/customers/`, {
    params: {
      limit: Number(pageSize),
      offset: +page * Number(pageSize),
      prescriber_user_id: userId,
    },
  });

  return data;
}

async function getPermissions({queryKey}: QueryFunctionContext<any[]>) {
  const [,] = queryKey;
  const {data} = await api.get<any[]>(`/permissions/menus/`, {
    params: {
      limit: 30,
    },
  });

  return data;
}

async function getUserPermissions({queryKey}: QueryFunctionContext<any[]>) {
  const [, userId] = queryKey;
  const {data} = await api.get<any[]>(
    `/permissions/user-permissions/${userId}/`
  );

  return data;
}

async function getVehicles() {
  const {data} = await api.get<ListResponse<Vehicle>>('/vehicles/');

  return data;
}

async function getEmployeeById({queryKey}: QueryFunctionContext) {
  const [, employeeId] = queryKey;

  if (!employeeId) return undefined;

  const {data} = await api.get<User>(`/users/${employeeId}`);

  return data;
}

function useEmployeeForm() {
  const {currentUser} = useAuth();
  const {employeeId} = useParams<{employeeId: string}>();
  const {push, replace} = useHistory();

  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState(
    sessionStorage.getItem('employee_tab') || ''
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(40);
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [viaSms, setViaSms] = useState(false);
  const {deleteEmployee, isDeleting} = useDeleteEmployee();

  const [userPermissions, setUserPermissions] = useState<Record<string, any>>(
    {}
  );

  const {
    handleSubmit,
    register,
    control,
    formState: {errors},
    reset,
    watch,
    setValue,
    getValues,
  } = useForm<FormValue>();

  const role = watch('role');
  const license_front = watch('license_front');
  const license_back = watch('license_back');

  const license_files = useMemo(
    function () {
      return [license_front, license_back].filter((item) => Boolean(item));
    },
    [license_front, license_back]
  );

  const {data: prescriptions, isLoading: prescriptionsLoading} = useQuery(
    ['user_prescriptions', employeeId, currentPage - 1, pageSize],
    getPrescriptions,
    {enabled: role?.label === 'Prescriber'}
  );
  const {data: customers, isLoading: customersLoading} = useQuery(
    ['user_customers', employeeId, currentPage - 1, pageSize],
    getCustomers
  );

  const {data: permissions} = useQuery(['permissions_menu'], getPermissions, {
    enabled: role?.label === 'Management',
  });

  const {data: user_permissions} = useQuery(
    ['permissions_user', employeeId],
    getUserPermissions,
    {
      enabled: role?.label === 'Management',
      refetchOnWindowFocus: false,
    }
  );

  const {data: vehicles} = useQuery('vehicles', getVehicles);
  const {data: employee} = useQuery(['employee', employeeId], getEmployeeById);

  const options = useMemo(
    function () {
      if (!vehicles) return [];

      return vehicles.results
        .filter((vehicle) => !vehicle.is_assigned)
        .map((v) => ({value: v.id, label: v.nickname}));
    },
    [vehicles]
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [status]);

  useEffect(() => {
    if (user_permissions) {
      const permissions: Record<string, any> = {};
      user_permissions.forEach((item) => {
        permissions[item.menu.id] = {
          ...item,
        };
      });
      setUserPermissions(permissions);
    }
  }, [user_permissions]);

  useEffect(
    function () {
      if (employee) {
        const {driver, ...user} = employee;
        if (!user) {
          return;
        }
        reset({
          ...user,
          phone: phoneFormat(user.phone),
          address_line_1: get(user, 'address.address_line_1', ''),
          organisation: get(user, 'organisation', ''),
          address_line_2: get(user, 'address.address_line_2', ''),
          city: get(user, 'address.city', ''),
          zipcode: get(user, 'address.zipcode', ''),
          state: get(user, 'address.state', ''),
          role: roles.find((r) => r.value === employee.role),
          vehicle: employee.driver?.vehicle
            ? {
                value: employee.driver?.vehicle.id,
                label: employee.driver?.vehicle.nickname,
              }
            : {value: 0, label: ''},
          document_number: driver?.document_number,
          document_state: {value: driver?.document_state},
          issue_state: states.find((s) => s.value === driver?.issue_state),
          license_front: driver?.license_front,
          license_back: driver?.license_back,
        });
      }
    },
    [employee, reset]
  );

  const handleFileUpload = useCallback(
    function (uploads: Upload[]) {
      setValue('license_front', uploads[0]);
      setValue('license_back', uploads[1]);
    },
    [setValue]
  );

  async function submit(data: FormValue) {
    setSubmitting(true);

    const user =
      role.value === 'prescriber'
        ? {
            ...data,
            address: {
              address_line_1: data.address_line_1,
              address_line_2: data.address_line_2,
              city: data.city,
              zipcode: data.zipcode,
              state: data.state,
              latitude: data.latitude,
              longitude: data.longitude,
            },
            first_name: data.first_name,
            last_name: data.last_name,
            username: data.username,
            email: data.email,
            phone: data.phone.replace(/\D/g, ''),
            npi_no: data.npi_no,
            role: data.role.value,
            is_active: data.is_active,
            password: data.password,
          }
        : {
            ...data,
            phone: data.phone.replace(/\D/g, ''),
            role: data.role.value,
          };

    try {
      const {data: userData} = await (employeeId
        ? api.patch<User>(`/users/${employeeId}/`, user)
        : api.post<User>('/users/', user));

      if (role.value === 'management' && employeeId) {
        const menu_permissions = Object.values(userPermissions).map((menu) => {
          return {
            menu_slug: menu.menu.slug,
            permissions: menu.permissions,
          };
        });
        await api.post('/permissions/assign-permission/', {
          user_id: employeeId,
          menu_permissions,
        });
      }

      if (user.role === 'driver') {
        const driver = {
          id: employee?.driver?.id,
          document_number: data.document_number,
          document_state: data.document_state?.value,
          issue_state: data.issue_state?.value,
          vehicle: data.vehicle?.value,
          license_front: data.license_front?.id,
          license_back: data.license_back?.id,
          user: userData.id,
        };

        if (driver.id) {
          await api.patch(`/drivers/${driver.id}/`, driver);
        } else {
          await api.post('/drivers/', driver);
        }
      }
      setSubmitting(false);

      push(
        role.value === 'prescriber' ? '/prescribers' : '/settings/employees'
      );
    } catch (error) {
      setSubmitting(false);
    }
  }

  const handleDeleteEmployee = useCallback(
    async (id: number) => {
      await deleteEmployee(id);

      replace(
        role.label === 'Prescriber' ? '/prescribers' : '/settings/employees'
      );
    },
    [deleteEmployee, role, replace]
  );

  const handleNavigateRequest = useCallback(
    (row: DataItem) => {
      push(`/orders/${row.id}`);
    },
    [push]
  );

  const handleInvitePrescriber = useCallback(
    async (isSms: boolean) => {
      try {
        await api.post(
          `/prescribers/${get(
            employee,
            'prescriber.id',
            0
          )}/send-notification/`,
          {
            is_sms: isSms,
            is_email: !isSms,
          }
        );
        setConfirmModal(false);
        toast.success('Prescriber invited successfully');
      } catch (error) {
        toast.error('Prescriber invitation failed');
      }
    },
    [employee]
  );

  const onChangePermission = (
    menu: number,
    permission: 'write' | 'read' | 'delete'
  ) => {
    const copied = {...userPermissions};
    copied[menu].permissions[permission] =
      !copied[menu].permissions[permission];
    setUserPermissions(copied);
  };

  return {
    currentUser,
    employeeId,
    options,
    submitting,
    errors,
    control,
    role: role?.label,
    license_files,
    register,
    handleFileUpload,
    getValues,
    setValue,
    watch,
    status,
    setStatus,
    userPermissions,
    prescriptions: prescriptions?.results || [],
    prescriptionsLoading,
    customers: customers?.results || [],
    customersLoading,
    totalPages:
      status === 'patients'
        ? Math.ceil((customers?.count || 0) / 20)
        : Math.ceil((prescriptions?.count || 0) / 20),
    count: status === 'patients' ? customers?.count : prescriptions?.count,
    currentPage,
    pageSize,
    setPageSize,
    setCurrentPage,
    employee,
    deleteModal,
    setDeleteModal,
    isDeleting,
    handleDeleteEmployee,
    handleNavigateRequest,
    handleInvitePrescriber,
    handleSubmit: handleSubmit(submit),
    confirmModal,
    setConfirmModal,
    viaSms,
    setViaSms,
    permissions: permissions || [],
    onChangePermission,
  };
}

export default useEmployeeForm;
