import {useParams} from 'react-router-dom';
import {Controller} from 'react-hook-form';
import Typography from 'components/Typography';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import FormActionGroup from 'components/FormActionGroup';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import SelectField from 'components/SelectField';
import useVehicleForm from './useVehicleForm';

function VehicleForm() {
  const {submitting, errors, options, control, register, handleSubmit} =
    useVehicleForm();
  const {vehicleId} = useParams<{vehicleId?: string}>();

  return (
    <form onSubmit={handleSubmit}>
      <PageHeader>
        <Typography variant="h2">
          {vehicleId ? 'Edit Vehicle' : 'New Vehicle'}
        </Typography>
        <FormActionGroup>
          <Button component="a" to="/settings/vehicles" color="gray">
            Cancel
          </Button>
          <Button type="submit" color="green" loading={submitting}>
            Save
          </Button>
        </FormActionGroup>
      </PageHeader>
      <FormGroup columns={2}>
        <TextField
          label="Nickname"
          placeholder="Nickname"
          {...register('nickname', {required: 'Required'})}
          error={errors.nickname?.message}
        />
        <TextField
          label="Vehicle Model"
          placeholder="Vehicle Model"
          {...register('model', {required: 'Required'})}
          error={errors.model?.message}
        />
      </FormGroup>

      <FormGroup columns={1}>
        <TextField
          label="License plate number"
          placeholder="License plate number"
          {...register('license_plate_number', {
            required: 'Required',
          })}
          error={errors.license_plate_number?.message}
        />
      </FormGroup>

      <FormGroup columns={1}>
        <Controller
          name="driver"
          control={control}
          render={({field, fieldState}) => (
            <SelectField
              label="Assigned Driver"
              placeholder="No assigned driver"
              options={options}
              error={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </FormGroup>
      <FormGroup columns={1}>
        <Controller
          name="vehicle_type"
          control={control}
          render={({field, fieldState}) => (
            <SelectField
              label="Vehicle type"
              placeholder="Select vehicle type"
              options={[
                {value: 'car', label: 'Car'},
                {value: 'bike', label: 'Bike'},
              ]}
              error={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </FormGroup>
    </form>
  );
}

export default VehicleForm;
