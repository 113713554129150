import classNames from 'classnames';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import Filter from './components/Filter';
import Ticket from './components/Ticket';
import SignedDocument from './components/SignedDocument';
import useDeliveryPlanner from './useDeliveryPlanner';
import stat_path from './icons/stat_path.svg';
import stat_stops from './icons/stat_stops.svg';
import stat_time from './icons/stat_time.svg';
import sort_icon from './icons/sort.svg';

import styles from './DeliveryPlanner.module.scss';
import AssignedHeader from './components/AssignedHeader';
import React, {Fragment} from 'react';
import nyPostalCodeBoroughOptions from 'shared/ny-postal-code-borough-options';
import {get, groupBy, map, sortBy} from 'lodash';
import {add, parseISO} from 'date-fns';
import {utcToZonedTime, format} from 'date-fns-tz';
import {
  getOrderDeliveryBorough,
  getOrderDeliveryTimePreference,
} from 'utils/order-helpers';

import SendRouteModal from 'components/SendRouteModal';
import FormGroup from 'components/FormGroup';
import PrintIcon from 'icons/PrintIcon';
import ReactToPrint from 'react-to-print';
import Modal from 'components/Modal';
import {Link} from 'react-router-dom';
import {useFormState} from 'react-hook-form';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import clockIcon from 'components/NoteForm/icons/clock.svg';
import CheckboxField from 'components/CheckboxField/CheckboxField';
import TextField from 'components/TextField';
import {components} from 'react-select';

import NewMap from './components/NewMap';
import Select from 'components/Select';

const locationOptionsGrouped = groupBy(nyPostalCodeBoroughOptions, 'label');
const locationOptions = sortBy(
  map(locationOptionsGrouped, (obj, key) => ({
    label: key,
    value: map(obj, 'value'),
  })),
  'label'
);

function DeliveryPlanner() {
  const {
    route,
    currentVehicle,
    vehicles,
    isLoading,
    unassignedOrders,
    selectedUnassignedOrders,
    selectedAssignedOrders,
    stats,
    isSubmittable,
    onChangeUnassignedCheckbox,
    onChangeAssignedCheckbox,
    onChangeRouteParams,
    onChangeStartTime,
    onSendRoute,
    sortBy,
    setSortBy,
    filter,
    // refetch,
    onFilterChange,
    assignSelected,
    moveToOrders,
    unassignSelected,
    calculate,
    onChangeData,
    date,
    preferenceTime,
    isFetching,
    roundedTime,
    searchKey,
    setSearch,
    count,
    fetchingAll,
    calculating: calculatingRoute,
    resetCalculate,
    sortOptions,
    search,
    handleDragEnd,
    recalculate,
    isRouteHistory,
    orderList,
    activeOrder,
    setActiveOrder,
    handleOrdersClick,
    handleOrdersClose,
    handleNoteClick,
    noteSubmit,
    onChangeCheckbox,
    onChangeCheckboxAssign,
    onChange,
    control,
    register,
    handleSubmit,
    noteModal,
    allChecked,
    allCheckedAssign,
    isSendRouteModalOpen,
    setIsSendRouteModalOpen,
    lastElementRef,
    orderListRef,
    moveOrderModal,
    setMoveToOrder,
  } = useDeliveryPlanner();
  const {isSubmitting} = useFormState({control});

  return (
    <div className={styles.root}>
      <SendRouteModal
        route={route}
        isOpen={isSendRouteModalOpen}
        onClose={() => setIsSendRouteModalOpen(false)}
        onSubmit={onSendRoute}
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className={styles.panel}>
          <div className={classNames(styles.panel__header)}>
            <span className={styles.panel__inbox_title}>
              Inbox: {count} Deliveries
            </span>
          </div>
          <div className={styles.panel__filtersCon}>
            <div className={styles.panel__filters}>
              <Filter
                onChange={onFilterChange}
                value={filter}
                options={[
                  {
                    label: 'Locations',
                    options: locationOptions,
                  },
                ]}
                allChecked={allChecked}
                date={date}
                onChangeCheckbox={onChangeCheckbox}
                onChangeData={onChangeData}
                onChangeTime={onChange}
                preferenceTime={preferenceTime}
              />

              {/* <Sort value={sortBy} onChange={(value) => setSortBy(value)} /> */}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative',
            }}
          >
            <TextField
              value={searchKey}
              onChange={(target) => setSearch(target.target.value)}
              wrapperClass={styles.searchWrapper}
              placeholder="Search..."
            />

            <div className={styles.sortSelect}>
              <Select
                value={sortBy}
                onChange={(val: any) => {
                  setSortBy(val);
                }}
                isSearchable={false}
                components={{
                  Control: (props) => (
                    <components.Control
                      {...props}
                      className={styles.sortController}
                    >
                      <img
                        className={styles.sortIcon}
                        src={sort_icon}
                        alt="sort"
                      />
                      {props.children}
                    </components.Control>
                  ),

                  DropdownIndicator: (props) => <></>,
                }}
                options={sortOptions}
              />
            </div>
          </div>
          <div className={styles.panel__content}>
            {!isRouteHistory && (
              <>
                {isLoading ? (
                  <div className={styles.spinner_loading}>
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <Droppable droppableId="list1">
                      {(provided: any) => (
                        <div
                          style={{minHeight: '70vh'}}
                          ref={provided.innerRef}
                        >
                          {unassignedOrders.map((ticket, index) => {
                            return (
                              <div
                                key={ticket.id}
                                ref={
                                  unassignedOrders.length === index + 1
                                    ? lastElementRef
                                    : null
                                }
                              >
                                <Draggable
                                  draggableId={String(ticket.id)}
                                  index={index}
                                >
                                  {(provided: any) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Ticket
                                        isAssigned={false}
                                        key={ticket.id}
                                        {...ticket}
                                        new_leaf_order={ticket.new_leaf_order}
                                        timePreference={getOrderDeliveryTimePreference(
                                          ticket
                                        )}
                                        borough={getOrderDeliveryBorough(
                                          ticket
                                        )}
                                        selected={selectedUnassignedOrders.includes(
                                          ticket.id
                                        )}
                                        onChangeCheckbox={
                                          onChangeUnassignedCheckbox
                                        }
                                        onOrdersClick={handleOrdersClick(
                                          ticket
                                        )}
                                        handleNoteClick={() => {
                                          handleNoteClick(ticket.id, '');
                                        }}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              </div>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    {isFetching && unassignedOrders.length > 0 && (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Spinner />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className={styles.panel__bottom}>
            <Button
              size="lg"
              style={{
                borderWidth: 1.8,
                borderColor: !selectedUnassignedOrders.length
                  ? '#ff3b245A'
                  : '#ff3b24',
                color: '#ff3b24',
              }}
              color="outlined"
              disabled={!selectedUnassignedOrders.length}
              onClick={() => setMoveToOrder(true)}
            >
              Remove from Inbox
            </Button>
            <Button
              size="lg"
              color="outlined"
              style={{
                borderWidth: 1.5,
                borderColor: !selectedUnassignedOrders.length
                  ? '#0072b36A'
                  : '#0072b3',
                color: '#0072b3',
              }}
              disabled={!selectedUnassignedOrders.length}
              onClick={assignSelected}
            >
              Move to Router
            </Button>
          </div>
        </div>
        <div className={styles.panel}>
          <AssignedHeader
            roundedTime={roundedTime}
            currentVehicle={currentVehicle}
            vehicles={vehicles?.results}
            startLocation={
              route.start_location
                ? {
                    name: route.start_location,
                    lat: route.start_latitude,
                    lng: route.start_longitude,
                  }
                : {
                    name: '1100 2nd Ave, New York, NY 10022',
                    lat: 40.7596958,
                    lng: -73.9650045,
                  }
            }
            recalculate={recalculate}
            routes={route.deliveries.length}
            start_time={route.start_time}
            endLocation={
              route.end_location
                ? {
                    name: route.end_location,
                    lat: route.end_latitude,
                    lng: route.end_longitude,
                  }
                : {
                    name: '1100 2nd Ave, New York, NY 10022',
                    lat: 40.7596958,
                    lng: -73.9650045,
                  }
            }
            onChange={onChangeRouteParams}
            onChangeStartTime={onChangeStartTime}
          />
          <div className={classNames(styles.panel__filters, styles.assign)}>
            {/* <div className={styles.panel__start}>
            <span>Starts at</span>
            <DateTimePicker
              value={route.start_time ? parseJSON(route.start_time) : undefined}
              onChange={onChangeStartTime}
            />
          </div> */}
            {!isRouteHistory && (
              <CheckboxField
                name={`checkbox`}
                className={classNames(styles.checkbox, styles.assignCheck)}
                label={allCheckedAssign ? `Uncheck all` : 'Check all'}
                checked={allCheckedAssign}
                onChange={onChangeCheckboxAssign}
              />
            )}
            <Button
              className={styles.panel__unassign}
              disabled={!selectedAssignedOrders.length}
              size="sm"
              onClick={unassignSelected}
            >
              Unassign
            </Button>
          </div>
          <div
            ref={orderListRef}
            style={{position: 'absolute', zIndex: -100, display: 'none'}}
            className={classNames(styles.panel__content, styles['--assigned'])}
          >
            <div>
              {route.deliveries.map((ticket, index) => {
                return (
                  <Ticket
                    isAssigned={true}
                    key={ticket.id}
                    isPrint={true}
                    showTime={route.status === 'processed' || isRouteHistory}
                    index={route.status === 'processed' ? index + 1 : undefined}
                    selected={selectedAssignedOrders.includes(ticket.id)}
                    onChangeCheckbox={onChangeAssignedCheckbox}
                    onOrdersClick={handleOrdersClick(ticket)}
                    {...ticket}
                    handleNoteClick={() => {
                      setActiveOrder(ticket);
                      handleNoteClick(
                        ticket.id,
                        String(get(ticket, 'staff_note', '') || '')
                      );
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div
            // ref={orderListRef}
            className={classNames(styles.panel__content, styles['--assigned'])}
          >
            <Droppable droppableId="list">
              {(provided: any) => (
                <div style={{minHeight: '70vh'}} ref={provided.innerRef}>
                  {route.deliveries.map((ticket, index) => {
                    // console.log('Ticket:', ticket);
                    return (
                      <div key={ticket.id}>
                        <Draggable
                          draggableId={String(ticket.id)}
                          index={index}
                        >
                          {(provided: any) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Ticket
                                isAssigned={true}
                                key={ticket.id}
                                showTime={
                                  route.status === 'processed' || isRouteHistory
                                }
                                index={index + 1}
                                selected={selectedAssignedOrders.includes(
                                  ticket.id
                                )}
                                isError={(
                                  get(route, 'wrong_addresses', []) as number[]
                                ).includes(ticket.id)}
                                onChangeCheckbox={onChangeAssignedCheckbox}
                                onOrdersClick={handleOrdersClick(ticket)}
                                {...ticket}
                                handleNoteClick={() => {
                                  setActiveOrder(ticket);
                                  handleNoteClick(
                                    ticket.id,
                                    String(get(ticket, 'staff_note', '') || '')
                                  );
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      </div>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div className={styles.panel__assigned_buttom}>
            <div className={styles.panel__assigned_summary}>
              {/* <span className={styles.panel__deliveries_counter}>
              {route.deliveries.length}{' '}
              {pluralize('Delivery', route.deliveries.length)}
            </span> */}
              <ReactToPrint
                trigger={() => (
                  <button className={styles.panel__deliveries_print_action}>
                    <PrintIcon size={16} />
                  </button>
                )}
                content={() => orderListRef.current}
                onBeforeGetContent={() => {
                  orderListRef.current?.setAttribute(
                    'style',
                    'display:block; z-index:-1000;position:absolute'
                  );
                }}
                onAfterPrint={() => {
                  orderListRef.current?.setAttribute(
                    'style',
                    'display:none; z-index:-1000;position:absolute'
                  );
                }}
                onPrintError={() => {
                  orderListRef.current?.setAttribute(
                    'style',
                    'display:none; z-index:-1000;position:absolute'
                  );
                }}
              />
              <div className={styles.panel__deliveries_end_time}>
                <img alt="timer" src={stat_time} />
                {/*<span>Ends at</span> {stats.end_time}*/}
                <span>Ends at</span>{' '}
                {route?.start_time && route.total_time
                  ? format(
                      utcToZonedTime(
                        add(parseISO(route.start_time), {
                          minutes: route.total_time,
                        }),
                        'America/New_York'
                      ),
                      'h:mm aaa',
                      {
                        timeZone: 'America/New_York',
                      }
                    )
                  : '-:-- --'}
              </div>
            </div>
            {route.status === 'processed' ? (
              <div className={styles.panel__assigned_stats}>
                <Fragment>
                  <div className={styles.panel__assigned_stat}>
                    <img alt="stat icon" src={stat_time} />
                    <span>{stats.total_time}</span>
                    <span>Total time</span>
                  </div>
                  <div className={styles.panel__assigned_stat}>
                    <img alt="stat icon" src={stat_path} />
                    <span>{stats.distance}</span>
                    <span>Distance</span>
                  </div>
                  <div className={styles.panel__assigned_stat}>
                    <img alt="stat icon" src={stat_stops} />
                    <span>{stats.stops}</span>
                    <span>Stops</span>
                  </div>
                </Fragment>
              </div>
            ) : null}

            <FormGroup columns={2} className={styles.panel__assigned_actions}>
              <Button
                loading={calculatingRoute}
                color="outlined"
                onClick={calculate}
                style={{
                  borderWidth: 1.5,
                  borderColor:
                    !route.deliveries.length || !isSubmittable || isRouteHistory
                      ? '#0072b36A'
                      : '#0072b3',
                  color: '#0072b3',
                }}
                disabled={
                  !route.deliveries.length || !isSubmittable || isRouteHistory
                }
              >
                Calculate
              </Button>
              <Button
                color="outlined"
                style={{
                  borderWidth: 1.5,
                  borderColor:
                    !isSubmittable ||
                    route.id === '0' ||
                    !route.deliveries.length ||
                    !search
                      ? '#2196546A'
                      : '#219654',
                  color: '#219654',
                }}
                disabled={
                  !isSubmittable ||
                  route.id === '0' ||
                  !route.deliveries.length ||
                  !search
                }
                onClick={() => setIsSendRouteModalOpen(true)}
              >
                Send Route
              </Button>
            </FormGroup>
          </div>
        </div>
      </DragDropContext>

      <div className={styles.panel}>
        <div className={classNames(styles.panel__header)}>
          {search ? (
            <span
              onClick={resetCalculate}
              className={styles.panel__change_vehicle}
            >
              Reset
            </span>
          ) : (
            <span className={styles.panel__inbox_title}>Preview</span>
          )}
          <Link to="/history/routes/page/1" className={styles.datetime}>
            <img className={styles.clock_icon} alt="clock" src={clockIcon} />
            <span className={styles.panel__change_vehicle}>History</span>
          </Link>
        </div>
        <NewMap
          routeType={get(route, 'vehicle.vehicle_type', 'car')}
          orders={route.deliveries}
          start_location={{
            name: route.start_location,
            lat: route.start_latitude,
            lng: route.start_longitude,
          }}
          end_location={{
            name: route.end_location,
            lat: route.end_latitude,
            lng: route.end_longitude,
          }}
          display={!!search || isRouteHistory}
        />
      </div>

      <Modal
        isOpen={!!orderList || noteModal}
        onClose={handleOrdersClose}
        onRequestClose={handleOrdersClose}
        className={styles.list_wrapper}
      >
        {orderList && (
          <>
            <div className={styles.list}>
              <div
                className={classNames(
                  styles.list__item_header,
                  styles.list__item
                )}
              >
                <p>Order #</p>
                <p style={{marginLeft: 30}}>RX #</p>
                <p>Preference date</p>
                {/* <p>Count of items</p> */}
              </div>
              {orderList?.map(
                ({new_leaf_order, rx_no, preference_date, preference_time}) => (
                  <Link key={new_leaf_order} to={`/requests/${new_leaf_order}`}>
                    <div className={classNames(styles.list__item)}>
                      <p className={styles.list__id}>#M{new_leaf_order}</p>
                      <p className={styles.list__id}>#{rx_no}</p>
                      <p className={styles.list__id}>
                        {preference_date || '--/--/----'}
                        {preference_time ? `/${preference_time}` : ''}
                      </p>
                      {/* <p className={styles.list__count}>
                        {items.length}
                      </p> */}
                    </div>
                  </Link>
                )
              )}
            </div>
            <div className={styles.list_total}>
              Total items:
              {orderList?.reduce(
                //@ts-ignore
                (prev, item) => prev + item.items.length,
                0
              )}
            </div>
          </>
        )}
        {noteModal && (
          <form
            className={styles.noteFormWrapper}
            onSubmit={handleSubmit(noteSubmit)}
          >
            <FormGroup>
              <div>Dispatch note for driver:</div>
              <textarea
                className={styles.noteInput}
                rows={5}
                {...register('driverNote')}
              />
            </FormGroup>
            <div className={styles.actions}>
              <Button
                color="outlined"
                size="md"
                onClick={() => handleOrdersClose()}
              >
                Close
              </Button>
              <Button
                type="submit"
                color="green"
                size="md"
                loading={isSubmitting}
              >
                Save
              </Button>
            </div>
          </form>
        )}
        {isRouteHistory ? (
          <>
            <div>Driver note for Dispatch:</div>
            {get(activeOrder, 'note', 'Empty!') !== null ? (
              <textarea
                disabled={true}
                value={get(activeOrder, 'note', 'Empty!')}
                className={styles.orderNote}
              />
            ) : (
              <textarea
                disabled={true}
                value={'Empty!'}
                className={styles.orderNote}
              />
            )}

            {!!get(activeOrder, 'delivery_image', null) && (
              <div className={styles.delivery_image}>
                <p>Delivery image:</p>
                <img
                  alt="delivery_image"
                  src={get(activeOrder, 'delivery_image.medium', '')}
                />
              </div>
            )}
            {/* <div
              className={classNames(
                styles.list__item_header,
                styles.list__item
              )}
            >
              <p>Signed by:</p>
              <p>{get(activeOrder, 'accepters_name', '')}</p>
            </div>
            <div
              className={classNames(
                styles.list__item_header,
                styles.list__item
              )}
            >
              <p>Signed at:</p>
              <p>{get(activeOrder, 'signed_at', '')}</p>
            </div> */}
            <SignedDocument driver={route.driver} order={activeOrder} />
            {/* {get(activeOrder, 'signature.file', '') !== '' ? (
              <img
                src={get(activeOrder, 'signature.file', '')}
                className={styles.signatureImage}
                alt="Signature by customer"
              />
            ) : (
              <textarea
                disabled={true}
                value={'No images, yet!'}
                className={styles.orderNote}
              />
            )} */}
          </>
        ) : null}
      </Modal>
      <Modal
        isOpen={moveOrderModal}
        onClose={() => setMoveToOrder(false)}
        onRequestClose={() => setMoveToOrder(false)}
        className={styles.list_wrapper}
      >
        <h3 style={{textAlign: 'center', marginTop: 30, marginBottom: 50}}>
          Are you sure you want to move your orders into Orders section ?
        </h3>
        <div className={styles.actions}>
          {/* <Button
            color="outlined"
            size="md"
            onClick={() => setMoveToOrder(false)}
          >
            Close
          </Button> */}
          <Button type="submit" color="green" size="md" onClick={moveToOrders}>
            Move
          </Button>
        </div>
      </Modal>
      <Modal
        showCloseButton={false}
        isOpen={fetchingAll}
        className={styles.list_wrapper}
      >
        <div style={{alignItems: 'center', textAlign: 'center'}}>
          <Spinner className={styles.fetchLoader} />
          <h3>Fetching all orders. Please wait...</h3>
        </div>
      </Modal>
      <Modal
        showCloseButton={false}
        isOpen={calculatingRoute}
        className={styles.list_wrapper}
      >
        <div style={{alignItems: 'center', textAlign: 'center'}}>
          <Spinner className={styles.fetchLoader} />
          <h3>Route calculating. Please wait...</h3>
        </div>
      </Modal>
    </div>
  );
}

export default DeliveryPlanner;
