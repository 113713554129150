import {QueryFunctionContext, useQuery} from 'react-query';
import {useRouteMatch} from 'react-router-dom';
import api from 'api';
import {Transaction} from 'types';
import {useState} from 'react';
import toast from 'react-hot-toast';
import {get} from 'lodash';

async function getRequestById({
  queryKey,
}: QueryFunctionContext): Promise<Transaction> {
  const [, id] = queryKey;
  const {data} = await api.get<Transaction>(`/payment/transactions/${id}/`);
  return {...data} as Transaction;
}

function useRequestDetails() {
  const {params} = useRouteMatch<{id: string}>();
  const {data} = useQuery(['request_details', params.id], getRequestById);

  const [confirmModal, setConfirmModal] = useState(false);

  const refundHandler = async () => {
    try {
      await api.post(`/payment/refund/`, {
        transaction_id: params.id,
        amount: data?.amount,
      });
      toast.success('Refund request sent');
      setConfirmModal(false);
    } catch (error) {
      toast.error(
        get(error, 'response.data.message') || 'Failed to send refund request'
      );
    }
  };

  return {
    data,
    id: params.id,
    confirmModal,
    setConfirmModal,
    refundHandler,
  };
}

export default useRequestDetails;
