import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {format} from 'date-fns';
import {QueryFunctionContext, useQuery} from 'react-query';

import Typography from 'components/Typography';
import StatusBadge from 'components/StatusBadge';
import ViewDetails from 'components/ViewDetails';

import {DataItem, ListResponse, Request} from 'types';
import useSort from 'hooks/useSort';
import api from 'api';
import {
  mapStatusColor,
  mapStatusText,
  mapMMStatusText,
  mapMMSubStatusColor,
} from 'constants/mappings';
import {get, reject} from 'lodash';
import {FilterOption} from 'pages/DeliveryPlanner/components/Filter/Filter';
import Button from 'components/Button';
import {RefillProps} from './RefillHandler';
import {useHistory, useRouteMatch} from 'react-router-dom';

async function getRequests({queryKey}: QueryFunctionContext<any[]>) {
  const [
    ,
    page,
    order,
    status,
    userId,
    keyWord,
    source,
    date,
    location,
    preference_time,
    onlyOTC,
    paid,
    pageSize,
  ] = queryKey;

  const {data} = await api.get<ListResponse<Request>>('/requests', {
    params: {
      order: order,
      status,
      user_id: userId,
      offset: +page * +pageSize,
      search: keyWord,
      limit: +pageSize,
      source: source,
      preference_date:
        source !== 'app' && date ? format(date, 'yyyy-MM-dd') : undefined,
      zip_code:
        location.length > 0
          ? location.reduce((pr: [], curr: any) => [...pr, curr.label], [])
          : undefined,
      preference_time:
        source !== 'app' && preference_time ? preference_time.value : undefined,
      only_otc:
        source !== 'app' && onlyOTC ? onlyOTC.value === 'true' : undefined,
      is_paid: source !== 'app' && paid ? paid.value === 'true' : undefined,
    },
  });

  return data;
}

function transformData(
  data: Request[],
  source: string,
  refetch: any,
  refillRef: any
) {
  return data.map((item) => ({
    ...item,
    rx_no:
      source === 'micromerchant'
        ? `${item.rx_no || ''}${item.refill_no ? ` (${item.refill_no})` : ''}
          `
        : item.rx_no,
    sync_time: get(item, 'sync_date', '') + '\n' + item.sync_time,
    bill_time:
      (get(item, 'bill_date', '') || '') + '\n' + (item.bill_time || ''),
    amount_due: `$${item.amount_due?.toFixed(2)}`,
    number: <Typography variant="strong">#M{item.id}</Typography>,
    drug_name:
      source === 'micromerchant'
        ? item.drug_name ?? ''
        : item.drug_name
        ? `${item.drug_name}...`
        : '',
    preference_date:
      item.preference_date || item.preference_time
        ? `${item.preference_date || '--/--/--'} / ${
            item.preference_time || '-'
          }`
        : '',

    status:
      source === 'micromerchant' ? (
        // @ts-ignore
        <StatusBadge color={mapMMSubStatusColor[item.status]}>
          {/*// @ts-ignore*/}
          {mapMMStatusText[item.status]}
        </StatusBadge>
      ) : (
        <StatusBadge color={mapStatusColor[item.status]}>
          {/*@ts-ignore*/}
          {mapStatusText[item.status]}
        </StatusBadge>
      ),
    view: <ViewDetails to={`/requests/${item.id}`} />,
    refill: (
      <RefillButton
        id={get(item, 'prescription_id', 0)}
        refillRef={refillRef}
      />
    ),
  }));
}

const RefillButton = ({refillRef, id}: {refillRef: any; id: any}) => {
  return (
    <Button
      onClick={(event) => {
        refillRef.current?.open(id);
        event.stopPropagation();
      }}
      style={{
        height: 30,
        color: '#0072b3',
        borderColor: '#0072b3',
      }}
      color="outlined"
    >
      Refill
    </Button>
  );
};

type RequestFilter = {
  userId?: string;
  source?: string;
  page?: number;
};

function useRequests(filter: RequestFilter) {
  const history = useHistory();
  const {params} = useRouteMatch<{page: string | undefined}>();
  const {sortBy, sortOrder, order, onSort} = useSort('id', 'desc');
  const [pageSize, setPageSize] = useState(40);
  const [source] = useState(filter.source || '');
  const [status, setStatus] = useState(
    sessionStorage.getItem('state') === 'all'
      ? ''
      : sessionStorage.getItem(
          source === 'app' ? 'requests_state' : 'orders_state'
        ) || ''
  );
  const [key, setKeyWord] = useState(
    sessionStorage.getItem(`searchKey_${source}`) || ''
  );
  const [keyWord, setApiKeyWord] = useState(
    sessionStorage.getItem(`searchKey_${source}`) || ''
  );
  const [location, setLocation] = useState<FilterOption[]>([]);

  const initialDate = sessionStorage.getItem('date')
    ? new Date(JSON.parse(sessionStorage.getItem('date') || ''))
    : undefined;

  const [date, setDate] = useState(initialDate);

  const initialTime = sessionStorage.getItem('preferenceTime')
    ? JSON.parse(sessionStorage.getItem('preferenceTime') || '')
    : undefined;

  const [preferenceTime, setPreferenceTime] = useState<
    undefined | {value: string; label: string}
  >(initialTime);

  const initialOTC = sessionStorage.getItem('onlyOTC')
    ? JSON.parse(sessionStorage.getItem('onlyOTC') || '')
    : undefined;

  const [onlyOTC, setOnlyOTC] = useState<
    undefined | {value: string; label: string}
  >(initialOTC);

  const initialPaid = sessionStorage.getItem('paid')
    ? JSON.parse(sessionStorage.getItem('paid') || '')
    : undefined;

  const [paid, setPaid] = useState<undefined | {value: string; label: string}>(
    initialPaid
  );
  const [currentPage, setCurrentPage] = useState(1);
  const refillRef = useRef<RefillProps>(null);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setApiKeyWord(key);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [key]);

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params.page]);

  useEffect(() => {
    if (paid) {
      sessionStorage.setItem('paid', JSON.stringify(paid));
    } else {
      sessionStorage.setItem('paid', '');
    }
    if (onlyOTC) {
      sessionStorage.setItem('onlyOTC', JSON.stringify(onlyOTC));
    } else {
      sessionStorage.setItem('onlyOTC', '');
    }
    if (preferenceTime) {
      sessionStorage.setItem('preferenceTime', JSON.stringify(preferenceTime));
    } else {
      sessionStorage.setItem('preferenceTime', '');
    }
    if (date) {
      sessionStorage.setItem('date', JSON.stringify(date));
    } else {
      sessionStorage.setItem('date', '');
    }
  }, [paid, onlyOTC, preferenceTime, date]);

  const {data, refetch, isLoading} = useQuery(
    [
      'requests',
      String(currentPage - 1 || 0),
      order,
      status,
      filter.userId || '',
      keyWord,
      source,
      date,
      location,
      preferenceTime,
      onlyOTC,
      paid,
      pageSize,
    ],
    getRequests
  );

  useEffect(() => {
    sessionStorage.setItem('location', JSON.stringify(location));
  }, [location]);

  const onChangeData = (val: any) => {
    setDate(val);
  };

  const onChangeLocation = (value: any) => {
    if (value) {
      setLocation((prev) =>
        prev.some((item) => item.label === value.label)
          ? reject(prev, value)
          : [...prev, value]
      );
    } else {
      setLocation([]);
    }
  };

  const tabs = useMemo(function () {
    return [
      {title: 'All', value: ''},
      {title: 'New', value: 'new'},
      {title: 'In-Progress', value: 'in_progress'},
      {title: 'Closed', value: 'completed'},
      {title: 'Invalid', value: 'invalid'},
      {title: 'Incomplete', value: 'incomplete'},
      {title: 'Call Doctor', value: 'call_doctor'},
    ];
  }, []);

  const MMtabs = useMemo(function () {
    return [
      {title: 'All', value: ''},
      {title: 'Unbilled', value: 'completed'},
      {title: 'Billed', value: 'billed'},
      {title: 'Filled', value: 'filled'},
      {title: 'OTC CheckOut', value: 'otc_status'},
      // {title: 'Paid', value: 'paid'},
      {title: 'Waiting for Pickup', value: 'waiting_for_pickup'},
      {title: 'Ready for Delivery', value: 'ready_for_delivery'},
      {title: 'Delivery Partner', value: 'delivery_partner'},
      {title: 'Picked-Up', value: 'picked_up'},
      {title: 'Delivered', value: 'delivered'},
      {title: 'Canceled', value: 'cancelled'},
      {title: 'Archived', value: 'archive'},
    ];
  }, []);

  const handleNavigateRequest = useCallback(
    (row: DataItem) => {
      if (source === 'app') {
        history.push(`/requests/${row.id}`);
      } else {
        history.push(`/orders/${row.id}`);
      }
    },
    [history, source]
  );

  return {
    history,
    refetch,
    data:
      data && !isLoading
        ? transformData(data.results, source, refetch, refillRef)
        : [],
    totalPages:
      data?.count && data.limit ? Math.ceil(data.count / data.limit) : 0,
    count: data?.count || 0,
    dailyCount: data?.dailyCount,
    loading: isLoading,
    sortBy,
    sortOrder,
    tabs,
    MMtabs,
    status,
    keyWord: key,
    setKeyWord,
    onSort,
    setStatus,
    source,
    // setSource,
    setDate,
    date,
    onChangeData,
    onChangeLocation,
    location,
    setPreferenceTime,
    preferenceTime,
    setOnlyOTC,
    onlyOTC,
    paid,
    setPaid,
    refillRef,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    handleNavigateRequest,
  };
}

export default useRequests;
