import Typography from 'components/Typography';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import FormActionGroup from 'components/FormActionGroup';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import ListWrapper from 'components/ListWrapper';
import CardContent from 'components/CardContent';
import {validateEmail} from 'utils/validators';

import useEmployeeForm from './useSettings';

import styles from './Settings.module.scss';
import {useState} from 'react';
import EyeIcon from './icons/eye.svg';
import EyeDisabledIcon from './icons/eye-disable.svg';
import {GooglePlaceAutoComplete} from 'components/GooglePlaceAutoComplete';
import {phoneFormat} from 'helpers/phoneFormat';

function EmployeeForm() {
  const {
    employeeId,
    submitting,
    errors,
    register,
    getValues,
    handleSubmit,
    setValue,
    employee,
  } = useEmployeeForm();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  if (!employee) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit}>
      <PageHeader>
        <Typography variant="h2">Profile</Typography>
        <FormActionGroup>
          {/* <Button to="/prescriptions" component="a" color="gray">
            Cancel
          </Button> */}
          <Button type="submit" color="green" loading={submitting}>
            Save
          </Button>
        </FormActionGroup>
      </PageHeader>
      <ListWrapper>
        <CardContent px="md" pt="none" pb="md">
          <TextField
            label="Organization name"
            placeholder="Organization name"
            {...register('organisation', {required: 'Required'})}
            error={errors.organisation?.message}
          />
          <FormGroup columns={2}>
            <TextField
              label="First name"
              placeholder="First name"
              {...register('first_name', {required: 'Required'})}
              error={errors.first_name?.message}
            />
            <TextField
              label="Last name"
              placeholder="Last name"
              {...register('last_name', {required: 'Required'})}
              error={errors.last_name?.message}
            />
          </FormGroup>

          <FormGroup columns={2}>
            <TextField
              label="Email address"
              placeholder="Email address"
              {...register('email', {
                required: 'Required',
                validate: validateEmail,
              })}
              error={errors.email?.message}
            />
            <TextField
              label="Phone number"
              placeholder="(123) 456-7890"
              {...register('phone', {
                required: 'Required',
                minLength: {
                  value: 14,
                  message: 'Enter a valid phone number',
                },
              })}
              onChange={(e) => {
                e.target.value = phoneFormat(e.target.value);
              }}
              error={errors.phone?.message}
            />
          </FormGroup>
          <FormGroup columns={2}>
            <TextField
              label="NPI number"
              placeholder="NPI number"
              {...register('npi_no', {
                required: 'Required',
              })}
              disabled
              error={errors.npi_no?.message}
            />
            <GooglePlaceAutoComplete
              defaultValue={
                getValues().address_line_1
                  ? `${getValues().address_line_1}, ${getValues().city}, ${
                      getValues().state
                    }, ${getValues().zipcode}`
                  : ''
              }
              label="Your address"
              placeholder="Address"
              onSelectPlace={(data) => {
                setValue('address_line_1', data.addressLine1);
                setValue('city', data.city);
                setValue('state', data.state);
                setValue('zipcode', data.zipcode);
                setValue('latitude', data.lat);
                setValue('longitude', data.lon);
              }}
              error={errors.address_line_1?.message}
              options={{
                types: ['address'],
              }}
            />
          </FormGroup>

          <Typography className={styles.header} variant="h3">
            Account
          </Typography>

          <FormGroup columns={3}>
            <TextField
              label="Username"
              placeholder="Username"
              {...register(
                'username',
                !employeeId
                  ? {
                      required: 'Required',
                    }
                  : {}
              )}
              error={errors.username?.message}
            />
            <div style={{position: 'relative'}}>
              <TextField
                label="Password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                {...register(
                  'password',
                  !employeeId
                    ? {
                        required: 'Required',
                      }
                    : {}
                )}
                error={errors.password?.message}
              />
              <img
                onClick={() => setShowPassword((pr) => !pr)}
                className={styles.eyeIcon}
                src={showPassword ? EyeIcon : EyeDisabledIcon}
                alt="eye"
              />
            </div>
            <div style={{position: 'relative'}}>
              <TextField
                label="Password confirmation"
                type={showPassword1 ? 'text' : 'password'}
                placeholder="Password confirmation"
                {...register(
                  'password_confirmation',
                  !employeeId
                    ? {
                        required: 'Required',
                      }
                    : {}
                )}
                error={errors.password_confirmation?.message}
              />
              <img
                onClick={() => setShowPassword1((pr) => !pr)}
                className={styles.eyeIcon}
                src={showPassword1 ? EyeIcon : EyeDisabledIcon}
                alt="eye"
              />
            </div>
          </FormGroup>
        </CardContent>
      </ListWrapper>
    </form>
  );
}

export default EmployeeForm;
