import {useCallback, useEffect, useMemo, useState} from 'react';
import {format} from 'date-fns';
import {QueryFunctionContext, useQuery} from 'react-query';

import Typography from 'components/Typography';
import StatusBadge from 'components/StatusBadge';
import ViewDetails from 'components/ViewDetails';

import {
  Address,
  Customer,
  DataItem,
  DataTableSchemaItem,
  ListResponse,
  Product,
  Request,
  SelectOption,
} from 'types';
import useSort from 'hooks/useSort';
import api from 'api';
import {
  mapStatusColor,
  mapStatusText,
  mapMMStatusText,
  mapMMSubStatusColor,
} from 'constants/mappings';
import {get, reject, sortBy as sortLodash} from 'lodash';
import {FilterOption} from 'pages/DeliveryPlanner/components/Filter/Filter';
import {useHistory, useRouteMatch} from 'react-router-dom';
import toast from 'react-hot-toast';

async function getRequests({queryKey}: QueryFunctionContext<any[]>) {
  const [
    ,
    page,
    order,
    status,
    userId,
    keyWord,
    source,
    date,
    billdate,
    createdDate,
    location,
    preference_time,
    onlyOTC,
    paid,
    statuses,
    type,
    pageSize,
  ] = queryKey;

  const notType = ['True', 'False', 'meta', 'landing_page'];

  const {data} = await api.get<ListResponse<Request>>('/requests', {
    params: {
      order: order,
      status: status || undefined,
      have_prescription:
        source === 'app' && (type?.value === 'True' || type?.value === 'False')
          ? type?.value.toLowerCase()
          : undefined,
      request_origin:
        source === 'app' &&
        type?.value !== 'True' &&
        type?.value !== 'False' &&
        type?.value
          ? type?.value
          : undefined,
      request_type:
        (!notType.includes(type?.value) && type?.value) || undefined,
      user_id: userId || undefined,
      offset: +page * pageSize,
      search: keyWord || undefined,
      limit: pageSize,
      source: source,
      preference_date:
        source !== 'app' && date ? format(date, 'yyyy-MM-dd') : undefined,
      bill_time_after:
        source !== 'app' && billdate.start
          ? format(billdate.start, 'yyyy-MM-dd')
          : undefined,
      bill_time_before:
        source !== 'app' && billdate.end
          ? format(billdate.end, 'yyyy-MM-dd')
          : undefined,
      start_date: createdDate.start
        ? format(createdDate.start, 'yyyy-MM-dd')
        : undefined,
      end_date: createdDate.end
        ? format(createdDate.end, 'yyyy-MM-dd')
        : undefined,
      zip_code:
        location.length > 0
          ? location.reduce((pr: [], curr: any) => [...pr, curr.label], [])
          : undefined,
      closed_statuses:
        statuses.length > 0 && status === 'completed'
          ? statuses.reduce((pr: [], curr: any) => [...pr, curr.value], [])
          : undefined,
      preference_time:
        source !== 'app' && preference_time ? preference_time.value : undefined,
      only_otc:
        source !== 'app' && onlyOTC ? onlyOTC.value === 'true' : undefined,
      is_paid: source !== 'app' && paid ? paid.value === 'true' : undefined,
    },
  });

  return data;
}

function transformData(data: Request[], source: string) {
  return data.map((item) => ({
    ...item,
    rx_no:
      source === 'micromerchant'
        ? `${item.rx_no || ''}${item.refill_no ? ` (${item.refill_no})` : ''}
          `
        : item.rx_no,
    sync_time: get(item, 'sync_date', '') + '\n' + item.sync_time,
    bill_time:
      (get(item, 'bill_date', '') || '') + '\n' + (item.bill_time || ''),
    amount_due: `$${item.amount_due?.toFixed(2)}`,
    number: <Typography variant="strong">#M{item.id}</Typography>,
    drug_name:
      source === 'micromerchant'
        ? item.drug_name ?? ''
        : item.drug_name
        ? `${item.drug_name}...`
        : '',
    preference_date:
      item.preference_date || item.preference_time
        ? `${item.preference_date || '--/--/--'} / ${
            item.preference_time || '-'
          }`
        : '',

    status:
      source === 'micromerchant' ? (
        // @ts-ignore
        <StatusBadge color={mapMMSubStatusColor[item.status]}>
          {/*// @ts-ignore*/}
          {mapMMStatusText[item.status]}
        </StatusBadge>
      ) : (
        <StatusBadge
          color={
            item.status === 'call_doctor' && item.is_resolved
              ? 'green'
              : mapStatusColor[item.status]
          }
        >
          {/*@ts-ignore*/}
          {mapStatusText[item.status]}
        </StatusBadge>
      ),
    view: <ViewDetails to={`/requests/${item.id}`} />,
  }));
}

const SCHEMA: DataTableSchemaItem[] = [
  {dataKey: 'number', header: 'Request #', sortable: false},
  {dataKey: 'rx_no', header: 'Rx #', sortable: false},
  {dataKey: 'sync_time', header: 'Sync time', sortable: true},
  {dataKey: 'customer', header: 'Patient', sortable: true},
  {dataKey: 'status', header: 'Status', sortable: true},
  {
    dataKey: 'drug_name',
    header: 'Drug name',
    sortable: false,
    colWidth: '270px',
  },
];
const MM_SCHEMA: DataTableSchemaItem[] = [
  {dataKey: 'rx_no', header: 'Rx #', sortable: true, index: 0},
  {dataKey: 'customer', header: 'Patient', sortable: true, index: 1},
  {dataKey: 'status', header: 'Status', sortable: true, index: 2},
  {
    dataKey: 'drug_name',
    header: 'Drug name',
    sortable: false,
    index: 3,
    colWidth: '250px',
  },
  {dataKey: 'amount_due', header: 'Amount', sortable: false, index: 4},
  {
    dataKey: 'number',
    header: 'Order #',
    sortable: true,
    align: 'center',
    index: 5,
  },
  {
    dataKey: 'sync_time',
    header: 'Sync time',
    sortable: true,
    align: 'center',
    index: 6,
  },
  {dataKey: 'bill_time', header: 'Bill Time', sortable: false, index: 7},
  {
    dataKey: 'preference_date',
    header: 'Preference date',
    sortable: false,
    index: 8,
  },
];

const MMtabs = [
  {title: 'All', value: ''},
  {title: 'Unbilled', value: 'completed'},
  {title: 'Billed', value: 'billed'},
  {title: 'Filled', value: 'filled'},
  {title: 'OTC CheckOut', value: 'otc_status'},
  {title: 'Waiting for Pickup', value: 'waiting_for_pickup'},
  {title: 'Ready for Delivery', value: 'ready_for_delivery'},
  {title: 'Delivery Partner', value: 'delivery_partner'},
  {title: 'Picked-Up', value: 'picked_up'},
  {title: 'Delivered', value: 'delivered'},
  {title: 'Canceled', value: 'cancel_script'},
  {title: 'Archived', value: 'archive'},
];

const tabs = [
  {title: 'All', value: ''},
  {title: 'New', value: 'new'},
  {title: 'In-Progress', value: 'in_progress'},
  {title: 'Closed', value: 'completed'},
  {title: 'Incomplete', value: 'incomplete'},
];

type RequestFilter = {
  userId?: string;
  source?: string;
  page?: number;
};

function useRequests(filterData: RequestFilter) {
  const history = useHistory();
  const {params} = useRouteMatch<{page: string | undefined}>();
  const [currentPage, setCurrentPage] = useState(parseInt(params.page || '1'));
  const {sortBy, sortOrder, order, onSort} = useSort('id', 'desc');
  const [source] = useState(filterData.source || '');
  const [pageSize, setPageSize] = useState(40);
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState(
    sessionStorage.getItem('state') === 'all'
      ? ''
      : sessionStorage.getItem(
          source === 'app' ? 'requests_state' : 'orders_state'
        ) || ''
  );
  const [key, setKeyWord] = useState(
    sessionStorage.getItem(`searchKey_${source}`) || ''
  );
  const [keyWord, setApiKeyWord] = useState(
    sessionStorage.getItem(`searchKey_${source}`) || ''
  );
  const initialLocation = sessionStorage.getItem(`location_${source}`)
    ? JSON.parse(sessionStorage.getItem(`location_${source}`) as any)
    : [];
  const [location, setLocation] = useState<FilterOption[]>(initialLocation);

  const initialStatuses = sessionStorage.getItem(`request_statuses`)
    ? JSON.parse(sessionStorage.getItem(`request_statuses`) as any)
    : [];
  const [statuses, setStatuses] = useState<FilterOption[]>(initialStatuses);
  const initialTypes = sessionStorage.getItem(`request_types_${source}`)
    ? JSON.parse(sessionStorage.getItem(`request_types_${source}`) as any)
    : undefined;
  const [types, setTypes] = useState<SelectOption | undefined>(initialTypes);

  const initialDate = sessionStorage.getItem('date')
    ? new Date(JSON.parse(sessionStorage.getItem('date') || ''))
    : undefined;

  const initialBillDate = sessionStorage.getItem('bill_date')
    ? {
        start: new Date(
          JSON.parse(sessionStorage.getItem('bill_date') || '').start
        ),
        end: new Date(
          JSON.parse(sessionStorage.getItem('bill_date') || '').end
        ),
      }
    : {
        start: undefined,
        end: undefined,
      };

  const initialCreatedDate = sessionStorage.getItem('createdDate')
    ? {
        start: new Date(
          JSON.parse(sessionStorage.getItem('createdDate') || '').start
        ),
        end: new Date(
          JSON.parse(sessionStorage.getItem('createdDate') || '').end
        ),
      }
    : {
        start: undefined,
        end: undefined,
      };

  const [date, setDate] = useState(initialDate);
  const [billdate, setBillDate] = useState(initialBillDate);
  const [createdDate, setCreatedDate] = useState(initialCreatedDate);

  const initialTime = sessionStorage.getItem('preferenceTime')
    ? JSON.parse(sessionStorage.getItem('preferenceTime') || '')
    : undefined;

  const [preferenceTime, setPreferenceTime] = useState<
    undefined | {value: string; label: string}
  >(initialTime);

  const initialOTC = sessionStorage.getItem(`onlyOTC_${source}`)
    ? JSON.parse(sessionStorage.getItem(`onlyOTC_${source}`) || '')
    : undefined;

  const [onlyOTC, setOnlyOTC] = useState<
    undefined | {value: string; label: string}
  >(initialOTC);

  const initialPaid = sessionStorage.getItem('paid')
    ? JSON.parse(sessionStorage.getItem('paid') || '')
    : undefined;

  const [paid, setPaid] = useState<undefined | {value: string; label: string}>(
    initialPaid
  );
  const [MMTable, setMMTable] = useState(
    MM_SCHEMA.map((item) => ({
      ...item,
      value: item.dataKey,
      label: item.header,
    }))
  );
  const [clients, setClients] = useState<Customer[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [filter, setFilter] = useState<any>({
    location: initialLocation,
    onlyOTC: initialOTC,
    paid: initialPaid,
    statuses: initialStatuses,
    types: initialTypes,
    billDate: initialBillDate,
    createdDate: initialCreatedDate,
    date: initialDate,
    preferenceTime: initialTime,
  });

  const [filterVisible, setFilterVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [productIds, setProductIds] = useState<any>([]);
  const [userId, setUserId] = useState<any>(0);
  const [addressId, setAddressId] = useState<any>(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [addressVisible, setAddressVisible] = useState(false);
  const [productVisible, setProductVisible] = useState(false);

  const filtered = useMemo(() => {
    return (
      (filter.location.length > 0 ||
        filter.statuses.length > 0 ||
        !!filter.billDate?.start ||
        !!filter.createdDate?.start ||
        !!filter.date ||
        !!filter.preferenceTime ||
        !!filter.onlyOTC ||
        !!filter.paid ||
        !!filter.types) &&
      !filterVisible
    );
  }, [filter, filterVisible]);

  const resetFilter = () => {
    setFilterVisible(false);
    const data = {
      location: [],
      onlyOTC: undefined,
      paid: undefined,
      statuses: [],
      billDate: {
        start: undefined,
        end: undefined,
      },
      createdDate: {
        start: undefined,
        end: undefined,
      },
      date: undefined,
      preferenceTime: undefined,
    };
    setFilter(data);
    filterHandler(data);
  };

  const filterHandler = (filter: any) => {
    setLocation(filter.location);
    // if (source === 'app') {
    //   setTypes(filter.types);
    // } else {
    //   if (
    //     filter.onlyOTC?.value === 'meds_kit' ||
    //     filter.onlyOTC?.value === 'mm_refill'
    //   ) {
    //     setTypes(filter.onlyOTC);
    //     setOnlyOTC(undefined);
    //   } else {
    //     setTypes(undefined);
    //   }
    // }
    setTypes(filter.types);
    setOnlyOTC(filter.onlyOTC);
    setPaid(filter.paid);
    setBillDate(filter.billDate);
    setCreatedDate(filter.createdDate);
    setDate(filter.date);
    setPreferenceTime(filter.preferenceTime);
    setStatuses(filter.statuses);
  };

  const applyFilter = () => {
    setFilterVisible(false);
    filterHandler(filter);
    setCurrentPage(1);
    history.push(
      source === 'micromerchant' ? '/orders/page/1' : '/requests/page/1'
    );
  };

  const onSearch = (val: string) => {
    setKeyWord(val);
    setCurrentPage(1);
    if (params.page !== '1') {
      if (source === 'app') {
        history.push('/requests/page/1');
      } else {
        history.push('/orders/page/1');
      }
    }
    sessionStorage.setItem(`searchKey_${source}`, val);
  };

  const getLocalTable = () => {
    let localTable = localStorage.getItem('tableData');
    if (localTable) {
      const data: any[] = MM_SCHEMA.filter((item) =>
        JSON.parse(localTable || '').find(
          (el: any) => el.dataKey === item.dataKey
        )
      );
      setMMTable(
        data.map((item) => ({...item, value: item.dataKey, label: item.header}))
      );
    }
  };

  const onChangeTab = (val: any) => {
    if (val) {
      const newTabs = MMTable.some((item) => item.label === val.label)
        ? reject(MMTable, val)
        : [...MMTable, val];

      setMMTable(sortLodash(newTabs, 'index'));

      localStorage.setItem(
        'tableData',
        JSON.stringify(sortLodash(newTabs, 'index'))
      );
    }
  };

  useEffect(() => {
    getLocalTable();
  }, []);

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params.page]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setApiKeyWord(key);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [key]);

  useEffect(() => {
    sessionStorage.setItem(`paid_${source}`, paid ? JSON.stringify(paid) : '');
    sessionStorage.setItem(
      `onlyOTC_${source}`,
      onlyOTC ? JSON.stringify(onlyOTC) : ''
    );
    sessionStorage.setItem(
      `preferenceTime_${source}`,
      preferenceTime ? JSON.stringify(preferenceTime) : ''
    );
    sessionStorage.setItem('date', date ? JSON.stringify(date) : '');
    sessionStorage.setItem(
      'bill_date',
      billdate.start ? JSON.stringify(billdate) : ''
    );
    sessionStorage.setItem(
      'created_date',
      createdDate.start ? JSON.stringify(createdDate) : ''
    );
    sessionStorage.setItem(
      'request_statuses',
      JSON.stringify(statuses.length > 0 ? statuses : [])
    );

    sessionStorage.setItem(
      `location_${source}`,
      location ? JSON.stringify(location) : ''
    );
    sessionStorage.setItem(
      `request_types_${source}`,
      types ? JSON.stringify(types) : ''
    );
  }, [
    paid,
    onlyOTC,
    preferenceTime,
    date,
    billdate,
    statuses,
    location,
    types,
    source,
    createdDate,
  ]);

  const {data, refetch, isLoading} = useQuery(
    [
      'requests',
      String(currentPage - 1 || 0),
      order,
      status,
      filter.userId || '',
      keyWord,
      source,
      date,
      billdate,
      createdDate,
      location,
      preferenceTime,
      onlyOTC,
      paid,
      statuses,
      types,
      pageSize,
    ],
    getRequests
  );

  const onChangeData = (val: any) => {
    setDate(val);
  };

  const onChangeStatuses = (value: any) => {
    if (value) {
      setStatuses((prev) =>
        prev.some((item) => item.label === value.label)
          ? reject(prev, value)
          : [...prev, value]
      );
    } else {
      setStatuses([]);
    }
  };
  const onChangeType = (value: any) => {
    setTypes(value);
  };

  const getProducts = async (page: number, keyWord: string) => {
    try {
      const {data} = await api.get<ListResponse<Product>>(`/products/`, {
        params: {
          limit: 50,
          offset: (page - 1) * 50,
          search: keyWord,
        },
      });
      setProducts((pr) =>
        page === 1 ? data.results : pr.concat(data.results)
      );
    } catch (error) {}
  };

  const getClients = async (page: number, keyWord: string) => {
    try {
      const {data} = await api.get<ListResponse<Customer>>(`/customers/`, {
        params: {
          limit: 50,
          offset: (page - 1) * 50,
          search: keyWord,
        },
      });
      setClients((pr) => (page === 1 ? data.results : pr.concat(data.results)));
    } catch (error) {}
  };

  const getAddress = async (id: number) => {
    setAddresses([]);
    try {
      const {data} = await api.get<Customer>(`/customers/${id}/`);
      setAddresses(data.addresses || []);
    } catch (error) {}
  };

  const handleNavigateRequest = useCallback(
    (row: DataItem) => {
      if (source === 'app') {
        history.push(`/requests/${row.id}`);
      } else {
        history.push(`/orders/${row.id}`);
      }
    },
    [history, source]
  );

  const onSubmit = () => {
    setSubmitting(true);
    api
      .post(source === 'app' ? 'requests/create/' : 'requests/create-order/', {
        customer_id: userId,
        address_id: addressId,
        drugs: productIds,
      })
      .then(({data}) => {
        refetch();
        setUserId(0);
        setAddressId(0);
        setProductIds([]);
        handleNavigateRequest(data);
        setVisible(false);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return {
    SCHEMA,
    MM_SCHEMA,
    MMTable,
    setMMTable,
    onChangeTab,
    clients,
    products,
    addresses,
    setClients,
    setProducts,
    setAddresses,
    getClients,
    getProducts,
    getAddress,
    filter,
    setFilter,
    resetFilter,
    filterVisible,
    setFilterVisible,
    applyFilter,
    filtered,
    onSubmit,
    submitting,
    handleNavigateRequest,
    currentPage,
    setCurrentPage,
    data: data && !isLoading ? transformData(data.results, source) : [],
    totalPages:
      data?.count && data.limit ? Math.ceil(data.count / data.limit) : 0,
    count: data?.count || 0,
    dailyCount: data?.dailyCount,
    loading: isLoading,
    sortBy,
    sortOrder,
    tabs,
    MMtabs,
    status,
    keyWord: key,
    setKeyWord,
    onSort,
    setStatus,
    source,
    onChangeData,
    location,
    preferenceTime,
    paid,
    onChangeStatuses,
    statuses,
    visible,
    setVisible,
    onChangeType,
    types,
    pageSize,
    setPageSize,
    onSearch,
    productVisible,
    setProductVisible,
    addressVisible,
    setAddressVisible,
    isModalOpen,
    setModalOpen,
    productIds,
    setProductIds,
    userId,
    setUserId,
    addressId,
    setAddressId,
    history,
  };
}

export default useRequests;
