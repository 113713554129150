import React, {useEffect, useMemo, useState} from 'react';
import {QueryFunctionContext, useQuery} from 'react-query';
import {get} from 'lodash';

import DataTable from 'components/DataTable';
import ListActionsWrapper from 'components/ListActionsWrapper';
import ListWrapper from 'components/ListWrapper';
import PageHeader from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import Typography from 'components/Typography';
import ViewDetails from 'components/ViewDetails';
import Pagination from 'components/Pagination';
import Modal from 'components/Modal';
import calendar from '../../DeliveryPlanner/icons/calendar.svg';
import api from 'api';
import {getFullName} from 'helpers/user';
import {ListResponse, Customer, SelectOption, DataTableSchemaItem} from 'types';

import styles from '../Settings.module.scss';
import userStyles from './Users.module.scss';
import {useLocation, useRouteMatch} from 'react-router-dom';
import Button from 'components/Button';
import FormGroup from '../../../components/FormGroup';
import TextField from '../../../components/TextField';
import {validateEmail} from '../../../utils/validators';
import FormActionGroup from '../../../components/FormActionGroup';
import toast from 'react-hot-toast';
import {format, parse} from 'date-fns';
import {useForm} from 'react-hook-form';
import useSort from 'hooks/useSort';
import useDebounce from 'hooks/useDebounce';
import ListTabs from 'pages/UserForm/ListTabs';
import FilterIcon from 'pages/Requests/FilterIcon';
import DateTimePicker from 'components/DateTimePicker';
// import MessageIcon from './MessageIcon';
import Select from 'components/Select';
import CheckIcon from './CheckIcon';
import {phoneFormat} from 'helpers/phoneFormat';

const TABS = [
  {title: 'All', value: 'all'},
  {title: 'To Invite', value: 'to_invite'},
  {title: 'Registered', value: 'registered'},
  {title: 'Active', value: 'active'},
];

async function getUsers({queryKey}: QueryFunctionContext<any[]>) {
  const [, keyWord, page, order, pageSize, status, metaDatas] = queryKey;
  const {data} = await api.get<ListResponse<Customer>>(`/customers/`, {
    params: {
      order: order,
      limit: pageSize,
      offset: +page * Number(pageSize),
      search: keyWord ? keyWord : undefined,
      status,
      last_prescription_date_after: metaDatas.last_prescription_date.startDate
        ? format(metaDatas.last_prescription_date.startDate, 'yyyy-MM-dd')
        : undefined,
      last_prescription_date_before: metaDatas.last_prescription_date.endDate
        ? format(metaDatas.last_prescription_date.endDate, 'yyyy-MM-dd')
        : undefined,
      total_prescriptions_range_min: metaDatas.total_prescriptions_range.from
        ? metaDatas.total_prescriptions_range.from
        : undefined,
      total_prescriptions_range_max: metaDatas.total_prescriptions_range.to
        ? metaDatas.total_prescriptions_range.to
        : undefined,
      date_joined_after: metaDatas.dataJoined.startDate
        ? format(metaDatas.dataJoined.startDate, 'yyyy-MM-dd')
        : undefined,
      date_joined_before: metaDatas.dataJoined.endDate
        ? format(metaDatas.dataJoined.endDate, 'yyyy-MM-dd')
        : undefined,
    },
  });

  return data;
}

function Users() {
  const [keyWord, setKeyWord] = useState(
    sessionStorage.getItem(`search_users`) || ''
  );
  const [status, setStatus] = useState(
    sessionStorage.getItem(`users_tab`) || 'all'
  );

  const [isModalOpen, setModalOpen] = useState(
    get(useLocation().state, 'isAdd', false)
  );
  const {sortBy, sortOrder, order, onSort} = useSort('id', 'desc');

  const {params} = useRouteMatch<{page: string | undefined}>();
  const [currentPage, setCurrentPage] = useState(parseInt(params.page || '1'));
  const [pageSize, setPageSize] = useState(40);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filter, setFilter] = useState({
    last_prescription_date: {
      startDate: sessionStorage.getItem('last_prescription_date_start')
        ? new Date(
            JSON.parse(
              sessionStorage.getItem('last_prescription_date_start') || ''
            )
          )
        : null,
      endDate: sessionStorage.getItem('last_prescription_date_end')
        ? new Date(
            JSON.parse(
              sessionStorage.getItem('last_prescription_date_end') || ''
            )
          )
        : null,
    },
    dataJoined: {
      startDate: sessionStorage.getItem('data_joined_start')
        ? new Date(
            JSON.parse(sessionStorage.getItem('data_joined_start') || '')
          )
        : null,
      endDate: sessionStorage.getItem('data_joined_end')
        ? new Date(JSON.parse(sessionStorage.getItem('data_joined_end') || ''))
        : null,
    },
    total_prescriptions_range: {
      from: sessionStorage.getItem('total_prescriptions_range_from')
        ? JSON.parse(
            sessionStorage.getItem('total_prescriptions_range_from') || ''
          )
        : null,
      to: sessionStorage.getItem('total_prescriptions_range_to')
        ? JSON.parse(
            sessionStorage.getItem('total_prescriptions_range_to') || ''
          )
        : null,
    },
  });
  const [metaDatas, setMetaDatas] = useState<any>({
    last_prescription_date: {
      startDate: sessionStorage.getItem('last_prescription_date_start')
        ? new Date(
            JSON.parse(
              sessionStorage.getItem('last_prescription_date_start') || ''
            )
          )
        : null,
      endDate: sessionStorage.getItem('last_prescription_date_end')
        ? new Date(
            JSON.parse(
              sessionStorage.getItem('last_prescription_date_end') || ''
            )
          )
        : null,
    },
    dataJoined: {
      startDate: sessionStorage.getItem('data_joined_start')
        ? new Date(
            JSON.parse(sessionStorage.getItem('data_joined_start') || '')
          )
        : null,
      endDate: sessionStorage.getItem('data_joined_end')
        ? new Date(JSON.parse(sessionStorage.getItem('data_joined_end') || ''))
        : null,
    },
    total_prescriptions_range: {
      from: sessionStorage.getItem('total_prescriptions_range_from')
        ? JSON.parse(
            sessionStorage.getItem('total_prescriptions_range_from') || ''
          )
        : null,
      to: sessionStorage.getItem('total_prescriptions_range_to')
        ? JSON.parse(
            sessionStorage.getItem('total_prescriptions_range_to') || ''
          )
        : null,
    },
  });
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [isMessageModalOpen, setMessageModalOpen] = useState(false);
  const [message, setMessage] = useState('');

  const searchDebounced = useDebounce(keyWord, 400);

  const {
    data: users,
    isLoading,
    refetch,
  } = useQuery(
    [
      'users',
      searchDebounced,
      String(currentPage - 1 || 0),
      order,
      String(pageSize),
      status,
      metaDatas,
    ],
    getUsers
  );

  const SCHEMA: DataTableSchemaItem[] = [
    {
      dataKey: 'checkbox',
      header:
        selectedUsers.length > 0 ? (
          <div
            onClick={(val) => {
              val.stopPropagation();
            }}
            className={userStyles.selectAll}
          >
            <div
              onClick={(val) => {
                setAllSelected(false);
                setSelectedUsers([]);
              }}
              className={userStyles.selectAllIcon}
            >
              <CheckIcon emptySelect={false} allSelected={allSelected} />
            </div>
            <Select
              components={{
                Input: () => null,
                ValueContainer: () => null,
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: 'none',
                  backgroundColor: 'transparent',
                }),
                container: (base) => ({
                  ...base,
                  width: 70,
                }),
              }}
              options={[
                {label: 'All', value: 'all'},
                {label: 'None', value: 'none'},
              ]}
              onChange={(val) => {
                if (val?.value === 'all') {
                  setAllSelected(true);
                } else {
                  setSelectedUsers([]);
                  setAllSelected(false);
                }
              }}
            />
          </div>
        ) : (
          <div style={{height: 40}}></div>
        ),
      sortable: false,
      colWidth: '60px',
    },
    {
      dataKey: 'name',
      header: 'Name',
      sortable: true,
    },

    {
      dataKey: 'phone',
      header: 'Phone',
    },
    {
      dataKey: 'email',
      header: 'Email',
    },
    {
      dataKey: 'total_prescriptions',
      header: 'Total Prescriptions',
      align: 'center',
      sortable: true,
    },
    {
      dataKey: 'last_prescription_date',
      header: 'Last Prescription Date',
      sortable: true,
      colWidth: '190px',
    },
    {
      dataKey: 'date',
      header: 'Date Joined',
      sortable: true,
      colWidth: '190px',
    },
    {
      dataKey: 'view',
      header: '',
    },
  ];

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params]);

  const data = useMemo(
    function () {
      return users
        ? users.results.map((u) => {
            return {
              ...u.user,
              ...u,
              phone: phoneFormat(u.user?.phone),
              name: getFullName(u.user),
              date: get(u, 'created_at', ''),
              view: <ViewDetails to={`/users/${u.id}/edit`} />,
              checkbox: (
                <div
                  onClick={(val) => {
                    val.stopPropagation();
                    if (allSelected) {
                      setSelectedUsers(
                        users.results
                          .map((i) => ({id: i.id}))
                          .filter((i) => i.id !== u.id)
                      );
                      setAllSelected(false);
                      return;
                    }
                    const include = selectedUsers.find((i) => i.id === u.id);
                    if (include) {
                      setSelectedUsers(
                        selectedUsers.filter((i) => i.id !== u.id)
                      );
                    } else {
                      setSelectedUsers([...selectedUsers, {...u, id: u.id}]);
                    }
                  }}
                >
                  <CheckIcon
                    emptySelect={
                      !selectedUsers.find((item) => item.id === u.id)
                    }
                    allSelected={
                      selectedUsers.find((item) => item.id === u.id) ||
                      allSelected
                    }
                  />
                </div>
              ),
            };
          })
        : [];
    },
    [users, selectedUsers, allSelected]
  );
  const handleOrdersClose = () => {
    setModalOpen(false);
  };

  const resetFilter = () => {
    setFilter({
      last_prescription_date: {
        startDate: null,
        endDate: null,
      },
      total_prescriptions_range: {
        from: null,
        to: null,
      },
      dataJoined: {
        startDate: null,
        endDate: null,
      },
    });
    setMetaDatas({
      last_prescription_date: {
        startDate: null,
        endDate: null,
      },
      total_prescriptions_range: {
        from: null,
        to: null,
      },
      dataJoined: {
        startDate: null,
        endDate: null,
      },
    });
    sessionStorage.removeItem('last_prescription_date_start');
    sessionStorage.removeItem('last_prescription_date_end');
    sessionStorage.removeItem('total_prescriptions_range_from');
    sessionStorage.removeItem('total_prescriptions_range_to');
    sessionStorage.removeItem('data_joined_start');
    sessionStorage.removeItem('data_joined_end');
    setFilterVisible(false);
  };

  const applyFilter = () => {
    if (filter.last_prescription_date.startDate) {
      sessionStorage.setItem(
        'last_prescription_date_start',
        JSON.stringify(filter.last_prescription_date.startDate || '')
      );
    } else {
      sessionStorage.removeItem('last_prescription_date_start');
    }
    if (filter.last_prescription_date.endDate) {
      sessionStorage.setItem(
        'last_prescription_date_end',
        JSON.stringify(filter.last_prescription_date.endDate || '')
      );
    } else {
      sessionStorage.removeItem('last_prescription_date_end');
    }
    if (filter.dataJoined.startDate) {
      sessionStorage.setItem(
        'data_joined_start',
        JSON.stringify(filter.dataJoined.startDate || '')
      );
    } else {
      sessionStorage.removeItem('data_joined_start');
    }
    if (filter.dataJoined.endDate) {
      sessionStorage.setItem(
        'data_joined_end',
        JSON.stringify(filter.dataJoined.endDate || '')
      );
    } else {
      sessionStorage.removeItem('data_joined_end');
    }
    if (filter.total_prescriptions_range.from) {
      sessionStorage.setItem(
        'total_prescriptions_range_from',
        JSON.stringify(filter.total_prescriptions_range.from || '')
      );
    } else {
      sessionStorage.removeItem('total_prescriptions_range_from');
    }
    if (filter.total_prescriptions_range.to) {
      sessionStorage.setItem(
        'total_prescriptions_range_to',
        JSON.stringify(filter.total_prescriptions_range.to || '')
      );
    } else {
      sessionStorage.removeItem('total_prescriptions_range_to');
    }
    setMetaDatas(filter);
    setCurrentPage(1);
    setFilterVisible(false);
  };

  const sendMessage = async () => {
    try {
      await api.post(
        '/customers/sms/blast/',
        {
          message,
          customer_ids: allSelected ? [] : selectedUsers.map((u) => u.id),
        },
        {
          params: {
            status,
            last_prescription_date_after: metaDatas.last_prescription_date
              .startDate
              ? format(metaDatas.last_prescription_date.startDate, 'yyyy-MM-dd')
              : undefined,
            last_prescription_date_before: metaDatas.last_prescription_date
              .endDate
              ? format(metaDatas.last_prescription_date.endDate, 'yyyy-MM-dd')
              : undefined,
            total_prescriptions_range_min: metaDatas.total_prescriptions_range
              .from
              ? metaDatas.total_prescriptions_range.from
              : undefined,
            total_prescriptions_range_max: metaDatas.total_prescriptions_range
              .to
              ? metaDatas.total_prescriptions_range.to
              : undefined,
          },
        }
      );
      toast.success('Message sent successfully');
      setMessageModalOpen(false);
      setMessage('');
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const filtered = useMemo(() => {
    return Object.values(filter).some((obj) =>
      Object.values(obj).some((value) => !!value)
    );
  }, [filter]);

  return (
    <div className={styles.main_container}>
      <PageHeader className={styles.top_header}>
        <Typography variant="h2">Patients</Typography>
        <div className={styles.count_wrapper}>
          <Typography textAlign="right" variant="h4">
            Total Patient Count:{' '}
            {Number(users?.count).toLocaleString('en-US') || 0}
          </Typography>
        </div>
      </PageHeader>
      <ListWrapper>
        <ListTabs
          tab="users_tab"
          tabs={TABS}
          activeTabValue={status}
          onChange={(val) => {
            setStatus(val);
            setCurrentPage(1);
          }}
        />
        <ListActionsWrapper className={styles.list_header}>
          <div className={userStyles.row}>
            <SearchInput
              value={keyWord}
              size="sm"
              onChange={(e) => {
                setKeyWord(e.target.value);
                setCurrentPage(1);
                sessionStorage.setItem(`search_users`, e.target.value);
              }}
            />
            <div
              className={userStyles.filterBtn}
              style={{
                borderColor: filtered ? '#0072b3' : '#E0E0E0',
              }}
              onClick={() => setFilterVisible(true)}
            >
              <FilterIcon isFilled={filtered} />
              <div
                style={{
                  color: '#28303F',
                }}
              >
                {filtered ? 'Filtered' : 'Filter'}
              </div>
            </div>
          </div>
          <div className={userStyles.row}>
            {(selectedUsers.length > 0 || allSelected) && (
              <Button
                onClick={() => setMessageModalOpen(true)}
                color="blue"
                size="sm"
                className={userStyles.messageIcon}
              >
                SMS
                {/* <MessageIcon /> */}
              </Button>
            )}
            <Button color="green" size="sm" onClick={() => setModalOpen(true)}>
              Add New Patient
            </Button>
          </div>
        </ListActionsWrapper>
        <DataTable
          data={data}
          schema={SCHEMA}
          loading={isLoading}
          sortBy={sortBy}
          // selectable
          sortOrder={sortOrder}
          onSort={onSort}
          // onClick={handleNavigateUser}
        />
        <Pagination
          count={users?.count || 0}
          pageSize={pageSize}
          setPageSize={setPageSize}
          page={currentPage}
          total={Math.round((users?.count || 0) / pageSize)}
          setCurrentPage={setCurrentPage}
        />
      </ListWrapper>
      <NewCustomer
        onSuccess={() => {
          refetch();
        }}
        isOpen={isModalOpen}
        onClose={handleOrdersClose}
      />
      <Modal
        isOpen={filterVisible}
        className={userStyles.filterModal}
        onClose={() => setFilterVisible(false)}
        onRequestClose={() => setFilterVisible(false)}
      >
        <Typography variant="h2">Filter</Typography>
        <div className={userStyles.filterContainer}>
          <Typography variant="h4">Last Prescription Date</Typography>
          <div className={userStyles.filterItem}>
            <DateTimePicker
              icon={calendar}
              showTimeSelect={false}
              dateFormat="MM/dd/yy E"
              value={filter.last_prescription_date.startDate}
              placeholder="Last prescription date from"
              className={styles.customInput}
              onChange={(data) => {
                setFilter((prev: any) => ({
                  ...prev,
                  last_prescription_date: {
                    ...prev.last_prescription_date,
                    startDate: data,
                  },
                }));
              }}
            />

            <DateTimePicker
              icon={calendar}
              showTimeSelect={false}
              dateFormat="MM/dd/yy E"
              value={filter.last_prescription_date.endDate}
              placeholder="Last prescription date to"
              className={styles.customInput}
              onChange={(data) => {
                setFilter((prev: any) => ({
                  ...prev,
                  last_prescription_date: {
                    ...prev.last_prescription_date,
                    endDate: data,
                  },
                }));
              }}
            />
          </div>
          <Typography variant="h4">Total Prescriptions</Typography>
          <div className={userStyles.filterItem}>
            <TextField
              wrapperClass={userStyles.filterInput}
              placeholder="Total prescriptions from"
              value={filter.total_prescriptions_range.from || ''}
              onChange={(e) => {
                setFilter((prev: any) => ({
                  ...prev,
                  total_prescriptions_range: {
                    ...prev.total_prescriptions_range,
                    from: e.target.value || null,
                  },
                }));
              }}
            />
            <TextField
              wrapperClass={userStyles.filterInput}
              placeholder="Total prescriptions to"
              value={filter.total_prescriptions_range.to || ''}
              onChange={(e) => {
                setFilter((prev: any) => ({
                  ...prev,
                  total_prescriptions_range: {
                    ...prev.total_prescriptions_range,
                    to: e.target.value || null,
                  },
                }));
              }}
            />
          </div>
          <Typography style={{marginBottom: 5}} variant="h4">
            Data Joined
          </Typography>
          {/* @ts-ignore */}
          <DateTimePicker
            icon={calendar}
            selectsRange={true}
            showTimeSelect={false}
            dateFormat="MM/dd/yy E"
            startDate={filter.dataJoined?.startDate}
            endDate={filter.dataJoined?.endDate}
            placeholder="Data joined"
            className={styles.customInput}
            onChange={(data) => {
              setFilter((prev: any) => ({
                ...prev,
                dataJoined: {
                  startDate: data[0] || undefined,
                  endDate: data[1] || undefined,
                },
              }));
            }}
          />
        </div>
        <div className={userStyles.filterBtnContainer}>
          <Button onClick={resetFilter} color="gray">
            Reset
          </Button>
          <Button onClick={applyFilter} color="blue">
            Apply
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={isMessageModalOpen}
        onClose={() => {
          setMessageModalOpen(false);
          setMessage('');
        }}
        className={userStyles.filterModal}
      >
        <Typography variant="h2">
          Send message to {allSelected ? users?.count : selectedUsers.length}{' '}
          patients
        </Typography>
        <textarea
          className={userStyles.messageTextarea}
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <FormActionGroup className={styles.buttonWrap}>
          <Button onClick={sendMessage} color="blue">
            Send
          </Button>
        </FormActionGroup>
      </Modal>
    </div>
  );
}

interface FormValue {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  birthday: string;
  company: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  zipcode: string;
  state: SelectOption;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (data: Customer) => void;
};

export function formatDateString(inputDate: string) {
  const numbersOnly = inputDate.replace(/[^0-9]/g, '');
  const month = numbersOnly.slice(0, 2);
  const day = numbersOnly.slice(2, 4);
  const year = numbersOnly.slice(4, 8);

  let formattedDate = '';
  if (month) {
    if (parseInt(month) > 12) {
      formattedDate += `12`;
    } else {
      formattedDate += month;
    }
    if (day) {
      if (parseInt(day) > 31) {
        formattedDate += `/31`;
      } else {
        formattedDate += `/${day}`;
      }
      if (year) {
        if (parseInt(year) > 2025) {
          formattedDate += `/2025`;
        } else {
          formattedDate += `/${year}`;
        }
      }
    }
  }

  if (formattedDate.length > 10) {
    formattedDate = formattedDate.slice(0, 10);
  }

  return formattedDate;
}

export const NewCustomer = ({isOpen, onClose, onSuccess}: Props) => {
  const {
    handleSubmit,
    register,
    formState: {errors},
    reset,
  } = useForm<FormValue>();

  const [date, setDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function handleDateChange(event: {target: {value: any}}) {
    const inputDate = event.target.value;
    const formattedDate = formatDateString(inputDate);
    setDate(formattedDate);
  }

  const submithandler = async (data: FormValue) => {
    setIsLoading(true);
    const user = {
      ...data,
      phone: data.phone.replace(/\D/g, ''),
      birthday: format(
        parse(data.birthday, 'MM/dd/yyyy', new Date()),
        'yyyy-MM-dd'
      ),
    };

    try {
      const data = await api.post('/customers/', user);
      onSuccess(data.data);
      setIsLoading(false);
      onClose();
      reset();
      setDate('');
    } catch (error) {
      toast.error(
        get(error, 'response.data.error_message', 'Something went wrong')
      );
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={styles.list_wrapper}>
      <div className={styles.formWrapper}>
        <FormGroup columns={2}>
          <TextField
            label="First name"
            placeholder="First name"
            {...register('first_name', {required: 'Required'})}
            error={errors.first_name?.message}
          />
          <TextField
            label="Last name"
            placeholder="Last name"
            {...register('last_name', {required: 'Required'})}
            error={errors.last_name?.message}
          />
        </FormGroup>

        <FormGroup columns={1}>
          <TextField
            label="Email address"
            placeholder="Email address"
            {...register('email', {
              required: 'Required',
              validate: validateEmail,
            })}
            error={errors.email?.message}
          />
        </FormGroup>

        <FormGroup columns={1}>
          <TextField
            label="Phone number"
            placeholder="(123) 456-7890"
            {...register('phone', {
              required: 'Required',
              minLength: {
                value: 14,
                message: 'Phone number length must be 14',
              },
            })}
            onChange={(e) => {
              e.target.value = phoneFormat(e.target.value);
            }}
            maxLength={14}
            error={errors.phone?.message}
          />
        </FormGroup>

        <FormGroup columns={1}>
          <TextField
            label="Date of birth"
            placeholder="mm/dd/yyyy"
            {...register('birthday', {
              required: 'Required',
            })}
            value={date}
            onChange={handleDateChange}
            maxLength={10}
            error={errors.birthday?.message}
          />
        </FormGroup>

        <FormActionGroup className={styles.buttonWrap}>
          <Button onClick={onClose} component="a" color="gray">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(submithandler)}
            type="submit"
            color="green"
            loading={isLoading}
          >
            Save
          </Button>
        </FormActionGroup>
      </div>
    </Modal>
  );
};

export default Users;
