import SearchInput from 'components/SearchInput';
import ListTabs from 'components/ListTabs';
import ListWrapper from 'components/ListWrapper';
import Typography from 'components/Typography';
import ListActionsWrapper from 'components/ListActionsWrapper';
import DataTable from 'components/DataTable';
import {Address} from 'types';
import styles from './Requests.module.scss';
import stat_time from '../DeliveryPlanner/icons/stat_time.svg';
import calendar from '../DeliveryPlanner/icons/calendar.svg';
import useRequests from './useRequests';
import Pagination from 'components/Pagination';
import DateTimePicker from 'components/DateTimePicker';
import Select from 'components/Select';
import {components} from 'react-select';
import {groupBy, isArray, map, reject, sortBy as sortLodash} from 'lodash';
import nyPostalCodeBoroughOptions from 'shared/ny-postal-code-borough-options';
import locationIcon from './location.svg';
import Button from 'components/Button';
import Modal from 'components/Modal';
import {NewCustomer} from 'pages/Settings/Users/Users';
import {CreateAddress} from 'pages/UserForm/UserAddresses/UserAddresses';
import {NewProduct} from 'pages/Products/Products';
import {addressToString} from 'helpers/address';
import classNames from 'classnames';
import FilterIcon from './FilterIcon';

const locationOptionsGrouped = groupBy(nyPostalCodeBoroughOptions, 'label');
const sorted: {label: string; value: any}[] = map(
  locationOptionsGrouped,
  (obj, key) => ({
    label: key,
    value: map(obj, 'value'),
  })
);

const locationOptions = sortLodash(sorted, 'label');

const statusesOptions = [
  {
    label: 'Statuses',
    options: [
      {
        label: 'Cancel script',
        value: 'cancel_script',
      },
      {
        label: 'No script available',
        value: 'no_script_available',
      },
      {
        label: 'Completed',
        value: 'completed',
      },
    ],
  },
];

const typesOptions = [
  {
    label: 'Call doctor',
    value: 'call_doctor',
  },
  {
    label: 'Meds kit',
    value: 'meds_kit',
  },
  {
    label: 'App refill',
    value: 'app_refill',
  },
  {
    label: 'Landing page',
    value: 'landing_page',
  },
  {
    label: 'Meta ads',
    value: 'meta_ads',
  },
  {
    label: 'Have prescription',
    value: 'True',
  },
  {
    label: 'No prescription',
    value: 'False',
  },
];

const paidOptions = [
  {label: 'Paid', value: 'true'},
  {label: 'Unpaid', value: 'false'},
];

type Props = {
  source: string;
};

function Requests({source}: Props) {
  const {
    SCHEMA,
    MM_SCHEMA,
    MMTable,
    onChangeTab,
    getClients,
    getProducts,
    getAddress,
    clients,
    products,
    addresses,
    setClients,
    setProducts,
    setAddresses,
    filter,
    setFilter,
    filterVisible,
    setFilterVisible,
    filtered,
    resetFilter,
    applyFilter,
    currentPage,
    setCurrentPage,
    data,
    totalPages,
    dailyCount,
    loading,
    sortBy,
    sortOrder,
    tabs,
    status,
    onSort,
    setStatus,
    keyWord,
    MMtabs,
    location,
    preferenceTime,
    paid,
    onChangeStatuses,
    statuses,
    visible,
    setVisible,
    pageSize,
    setPageSize,
    count,
    onSubmit,
    submitting,
    handleNavigateRequest,
    onSearch,
    productVisible,
    setProductVisible,
    addressVisible,
    setAddressVisible,
    isModalOpen,
    setModalOpen,
    productIds,
    setProductIds,
    userId,
    setUserId,
    addressId,
    setAddressId,
    history,
  } = useRequests({source});

  return (
    <>
      <div className={styles.top_header}>
        <SearchInput
          className={styles.input}
          value={keyWord}
          onChange={(val) => {
            onSearch(val.target.value);
          }}
          placeholder="Search"
        />
        <div className={styles.headerRight}>
          {source !== 'app' && (
            <Button
              onClick={() => {
                setVisible(true);
                getClients(1, '');
                getProducts(1, '');
              }}
              color="blue"
            >
              {source === 'app' ? 'Add request' : 'Add Order'}
            </Button>
          )}
          <Typography variant="h4">
            {source === 'app' ? 'Today’s App Requests' : 'Today’s Orders :'}{' '}
            {dailyCount}
          </Typography>
        </div>
      </div>
      <ListWrapper>
        <ListTabs
          onChange={(val) => {
            setStatus(val);
            setCurrentPage(1);
          }}
          isSupportPage={false}
          activeTabValue={status}
          tabs={source === 'micromerchant' ? MMtabs : tabs}
        />
        <ListActionsWrapper>
          <div className={styles.row}>
            <div
              className={styles.filterBtn}
              style={{
                borderColor: filtered ? '#0072b3' : '#E0E0E0',
              }}
              onClick={() => setFilterVisible(true)}
            >
              <FilterIcon isFilled={filtered} />
              <div
                style={{
                  color: '#28303F',
                }}
              >
                {filtered ? 'Filtered' : 'Filter'}
              </div>
            </div>

            {/* {source === 'app' && (
              <Select
                className={'DeliveryPlanner__Filter'}
                placeholder="Location"
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !location ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  Control: ({children, ...props}) => {
                    return (
                      <components.Control {...props}>
                        <img
                          className={styles.locationIcon}
                          src={locationIcon}
                          alt="location_icon"
                        />
                        {children}
                      </components.Control>
                    );
                  },
                  SingleValue: ({children, ...props}) => {
                    return (
                      <components.SingleValue {...props}>
                        {isArray(props.selectProps.value)
                          ? props.selectProps.value
                              .map((item: {label: string}) => item.label)
                              .join(', ')
                          : ''}
                      </components.SingleValue>
                    );
                  },
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
                onChange={(data) => {
                  history.push('/requests/page/1');
                  onChangeLocation(data);
                }}
                isClearable
                options={[
                  {
                    label: 'Locations',
                    options: locationOptions,
                  },
                ]}
                value={location}
              />
            )} */}
            {source === 'app' && status === 'completed' && (
              <Select
                className={classNames(
                  'DeliveryPlanner__Filter',
                  styles.selectCon,
                  styles.selectStatus
                )}
                placeholder="Status"
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !(statuses.length > 0) ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  SingleValue: ({children, ...props}) => {
                    return (
                      <components.SingleValue {...props}>
                        {isArray(props.selectProps.value)
                          ? props.selectProps.value
                              .map((item: {label: string}) => item.label)
                              .join(', ')
                          : ''}
                      </components.SingleValue>
                    );
                  },
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
                onChange={(data) => {
                  history.push('/requests/page/1');
                  onChangeStatuses(data);
                }}
                isClearable
                options={statusesOptions}
                value={statuses}
              />
            )}
            {/* {source === 'app' && (
              <Select
                className={classNames(styles.selectCon, styles.selectStatus)}
                placeholder="Type"
                isClearable
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !types ? <components.DropdownIndicator {...props} /> : null,
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
                onChange={(data) => {
                  history.push('/requests/page/1');
                  onChangeType(data);
                }}
                options={typesOptions}
                value={types}
              />
            )} */}
          </div>

          {source === 'micromerchant' && (
            <div className={styles.row}>
              <Select
                className={'Table_Select'}
                styles={{
                  container: (base) => ({
                    ...base,
                    marginLeft: 0,
                  }),
                }}
                isSearchable={false}
                onChange={onChangeTab}
                options={[
                  {
                    label: 'Table column',
                    options: MM_SCHEMA.map((item) => ({
                      ...item,
                      value: item.dataKey,
                      label: item.header,
                    })),
                  },
                ]}
                value={MMTable}
                placeholder={
                  <span className="select-placeholder-text">Display</span>
                }
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !preferenceTime ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  SingleValue: ({children, ...props}) => {
                    return (
                      <components.SingleValue {...props}>
                        Display
                      </components.SingleValue>
                    );
                  },
                  IndicatorSeparator: () => null,
                }}
                closeMenuOnSelect={false}
              />
            </div>
          )}
        </ListActionsWrapper>
        <DataTable
          schema={source === 'app' ? SCHEMA : MMTable}
          data={data}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onClick={handleNavigateRequest}
          onSort={onSort}
          loading={loading}
        />
        {!loading && (
          <Pagination
            count={count}
            basePath={source === 'app' ? '/requests' : '/orders'}
            page={currentPage}
            total={totalPages}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
          />
        )}
      </ListWrapper>
      <Modal
        isOpen={filterVisible}
        className={styles.filterModal}
        onClose={() => setFilterVisible(false)}
        onRequestClose={() => setFilterVisible(false)}
      >
        <Typography variant="h2">Filter</Typography>
        <div className={styles.filterContainer}>
          <Select
            className={'DeliveryPlanner__Filter'}
            placeholder="Location"
            menuPosition="fixed"
            components={{
              Input: () => null,
              DropdownIndicator: (props) =>
                !location ? <components.DropdownIndicator {...props} /> : null,
              Control: ({children, ...props}) => {
                return (
                  <components.Control {...props}>
                    <img
                      className={styles.locationIcon}
                      src={locationIcon}
                      alt="location_icon"
                    />
                    {children}
                  </components.Control>
                );
              },
              SingleValue: ({children, ...props}) => {
                return (
                  <components.SingleValue {...props}>
                    {isArray(props.selectProps.value)
                      ? props.selectProps.value
                          .map((item: {label: string}) => item.label)
                          .join(', ')
                      : ''}
                  </components.SingleValue>
                );
              },
              IndicatorSeparator: () => null,
            }}
            isSearchable={false}
            onChange={(data) => {
              let location: any = [];
              if (data) {
                location = filter.location.some(
                  (item: any) => item.label === data.label
                )
                  ? reject(filter.location, data)
                  : [...filter.location, data];
              }
              setFilter((prev: any) => ({
                ...prev,
                location,
              }));
            }}
            isClearable
            options={[
              {
                label: 'Locations',
                options: locationOptions,
              },
            ]}
            defaultValue={filter.location}
            value={filter.location}
          />

          <div className={styles.row}>
            {source === 'app' ? (
              <>
                <Select
                  className={classNames(styles.selectCon)}
                  placeholder="Type"
                  isClearable
                  components={{
                    Input: () => null,
                    DropdownIndicator: (props) =>
                      !filter.types ? (
                        <components.DropdownIndicator {...props} />
                      ) : null,
                    IndicatorSeparator: () => null,
                  }}
                  isSearchable={false}
                  onChange={(data) => {
                    setFilter((prev: any) => ({
                      ...prev,
                      types: data || undefined,
                    }));
                  }}
                  options={typesOptions}
                  value={filter.types}
                />
                {/* @ts-ignore */}
                <DateTimePicker
                  icon={calendar}
                  selectsRange={true}
                  showTimeSelect={false}
                  dateFormat="MM/dd/yy E"
                  startDate={filter.createdDate?.start}
                  endDate={filter.createdDate?.end}
                  placeholder="Created date"
                  className={styles.customInput}
                  onChange={(data) => {
                    setFilter((prev: any) => ({
                      ...prev,
                      createdDate: {
                        start: data[0] || undefined,
                        end: data[1] || undefined,
                      },
                    }));
                  }}
                />
              </>
            ) : (
              <>
                <Select
                  className={styles.selectCon}
                  isSearchable={false}
                  menuPosition="fixed"
                  onChange={(val: any) => {
                    setFilter((prev: any) => ({
                      ...prev,
                      onlyOTC: val || undefined,
                    }));
                  }}
                  options={[
                    {label: 'Rx', value: 'false'},
                    {label: 'Rx Refill ', value: 'mm_refill'},
                    {label: 'OTC', value: 'true'},
                    {label: 'Meds Kit', value: 'meds_kit'},
                  ]}
                  value={filter.onlyOTC}
                  placeholder={
                    <span className="select-placeholder-text">Type</span>
                  }
                  isClearable
                  components={{
                    Input: () => null,
                    DropdownIndicator: (props) =>
                      !filter.onlyOTC ? (
                        <components.DropdownIndicator {...props} />
                      ) : null,
                    IndicatorSeparator: () => null,
                  }}
                />
                <Select
                  className={styles.selectCon}
                  isSearchable={false}
                  menuPosition="fixed"
                  onChange={(val: any) => {
                    setFilter((prev: any) => ({
                      ...prev,
                      paid: val || undefined,
                    }));
                  }}
                  options={paidOptions}
                  value={filter.paid}
                  placeholder={
                    <span className="select-placeholder-text">Paid/Unpaid</span>
                  }
                  isClearable
                  components={{
                    Input: () => null,
                    DropdownIndicator: (props) =>
                      !paid ? (
                        <components.DropdownIndicator {...props} />
                      ) : null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </>
            )}
          </div>

          {source !== 'app' && (
            <>
              <div className={styles.row}>
                {/* @ts-ignore */}
                <DateTimePicker
                  icon={calendar}
                  selectsRange={true}
                  showTimeSelect={false}
                  dateFormat="MM/dd/yy E"
                  startDate={filter.billDate?.start}
                  endDate={filter.billDate?.end}
                  placeholder="Bill date"
                  className={styles.customInput}
                  onChange={(data) => {
                    setFilter((prev: any) => ({
                      ...prev,
                      billDate: {
                        start: data[0] || undefined,
                        end: data[1] || undefined,
                      },
                    }));
                  }}
                />
                {/* @ts-ignore */}
                <DateTimePicker
                  icon={calendar}
                  selectsRange={true}
                  showTimeSelect={false}
                  dateFormat="MM/dd/yy E"
                  startDate={filter.createdDate?.start}
                  endDate={filter.createdDate?.end}
                  placeholder="Created date"
                  className={styles.customInput}
                  onChange={(data) => {
                    setFilter((prev: any) => ({
                      ...prev,
                      createdDate: {
                        start: data[0] || undefined,
                        end: data[1] || undefined,
                      },
                    }));
                  }}
                />
              </div>
              <div className={styles.row}>
                <DateTimePicker
                  icon={calendar}
                  showTimeSelect={false}
                  dateFormat="MM/dd/yy E"
                  value={filter.date}
                  placeholder="Pr. date"
                  className={styles.customInput}
                  onChange={(data) => {
                    setFilter((prev: any) => ({
                      ...prev,
                      date: data,
                    }));
                  }}
                />

                <Select
                  className={styles.selectCon}
                  isSearchable={false}
                  menuPosition="fixed"
                  onChange={(val: any) => {
                    setFilter((prev: any) => ({
                      ...prev,
                      preferenceTime: val,
                    }));
                  }}
                  options={[
                    {label: 'Morning', value: 'morning'},
                    {label: 'Afternoon', value: 'afternoon'},
                    {label: 'Evening', value: 'evening'},
                  ]}
                  value={filter.preferenceTime}
                  placeholder={
                    <span className="select-placeholder-text">Pr. time</span>
                  }
                  isClearable
                  components={{
                    Input: () => null,
                    DropdownIndicator: (props) =>
                      !preferenceTime ? (
                        <components.DropdownIndicator {...props} />
                      ) : null,
                    Control: ({children, ...props}) => (
                      <components.Control {...props} className="select-row">
                        <img className={'timer'} src={stat_time} alt="timer" />
                        {children}
                      </components.Control>
                    ),

                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className={styles.filterBtnContainer}>
          <Button onClick={resetFilter} color="gray">
            Reset
          </Button>
          <Button onClick={applyFilter} color="blue">
            Apply
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={visible}
        onClose={() => setVisible(false)}
        className={styles.list_wrapper}
        onRequestClose={() => setVisible(false)}
      >
        <Typography variant="h2">
          Add new {source === 'app' ? 'request' : 'order'}
        </Typography>
        <div
          style={{display: 'flex', alignItems: 'center', gap: 10}}
          className={styles.label}
        >
          <div>Patient </div>
          <PlusComponent onClick={() => setModalOpen(true)} />
        </div>
        <Select
          onInputChange={(text, action) => {
            if (action.action === 'input-change') {
              getClients(1, text);
            }
          }}
          onMenuScrollToBottom={(event) => {
            getClients(Math.floor(clients.length / 50) + 1, '');
          }}
          menuPosition="fixed"
          placeholder="Click to select"
          onChange={(item) => {
            setUserId(item?.value);
            getAddress(Number(item?.value));
          }}
          value={
            clients.find((item) => item.id === userId)
              ? {
                  value:
                    clients.find((item) => item.id === userId)?.user?.id || '',
                  label:
                    clients.find((item) => item.id === userId)?.user
                      ?.first_name +
                    ' ' +
                    clients.find((item) => item.id === userId)?.user?.last_name,
                }
              : undefined
          }
          options={clients.map((item) => ({
            value: item.id,
            label: item.user.first_name + ' ' + item.user.last_name,
          }))}
        />
        {userId !== 0 && (
          <>
            <div
              style={{display: 'flex', alignItems: 'center', gap: 10}}
              className={styles.label}
            >
              <div>Address</div>
              <PlusComponent onClick={() => setAddressVisible(true)} />
            </div>
            <Select
              placeholder="Click to select"
              menuPosition="fixed"
              onChange={(item) => {
                setAddressId(item?.value);
              }}
              value={
                addresses.find((item) => item.id === addressId)
                  ? {
                      value:
                        addresses.find((item) => item.id === addressId)?.id ||
                        '',
                      label: addressToString(
                        addresses.find(
                          (item) => item.id === addressId
                        ) as Address
                      ),
                    }
                  : undefined
              }
              options={addresses.map((item) => ({
                value: item.id,
                label:
                  item.address_line_1 +
                  ',' +
                  item.city +
                  ',' +
                  item.state +
                  ',' +
                  item.zipcode,
              }))}
            />
          </>
        )}
        <div
          style={{display: 'flex', alignItems: 'center', gap: 10}}
          className={styles.label}
        >
          <div>Product</div>
          <PlusComponent onClick={() => setProductVisible(true)} />
        </div>
        <Select
          onInputChange={(text, action) => {
            if (action.action === 'input-change') {
              getProducts(1, text);
            }
          }}
          onMenuScrollToBottom={(event) => {
            getProducts(Math.floor(products.length / 50) + 1, '');
          }}
          placeholder="Click to select"
          menuPosition="fixed"
          onChange={(item) => {
            if (isArray(item)) {
              setProductIds(item.map((item) => item.value));
            } else {
              setProductIds([item?.value]);
            }
          }}
          value={
            productIds.length > 0
              ? {
                  value:
                    products.find((item) => item.id === productIds[0])?.id ||
                    '',
                  label: products.find((item) => item.id === productIds[0])
                    ?.name,
                }
              : undefined
          }
          //@ts-ignore
          isSearchable={true}
          //@ts-ignore
          isMulti={source === 'app'}
          options={products.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
        />

        <Button
          loading={submitting}
          disabled={!addressId || !userId || !productIds.length}
          className={styles.addBtn}
          onClick={onSubmit}
          color="blue"
        >
          {source === 'app' ? 'Add request' : 'Add Order'}
        </Button>
        <NewCustomer
          onSuccess={(data) => {
            setClients((pr) => [data, ...pr]);
            setUserId(data.id);
          }}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />

        <CreateAddress
          customer_id={userId}
          handleClose={() => setAddressVisible(false)}
          onSuccess={(data) => {
            setAddresses((pr) => [data, ...pr]);
            setAddressId(data.id);
          }}
          isOpen={addressVisible}
        />
        <NewProduct
          visible={productVisible}
          onClose={() => setProductVisible(false)}
          onSuccess={(data) => {
            setProducts((pr) => [data, ...pr]);
            setProductIds([data.id]);
          }}
        />
      </Modal>
    </>
  );
}

export const PlusComponent = ({onClick}: {onClick: () => void}) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: 22,
        height: 22,
        display: 'flex',
        backgroundColor: '#27ae60',
        color: '#fff',
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      +
    </div>
  );
};

export default Requests;
