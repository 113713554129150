import React from 'react';

function Prescribers() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="10"
        cy="17.5"
        rx="7"
        ry="3.5"
        stroke="#222222"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <circle
        cx="10"
        cy="7"
        r="4"
        stroke="#222222"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M21 11H17"
        stroke="#222222"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M19 9L19 13"
        stroke="#222222"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default Prescribers;
