// import {useHistory} from 'react-router-dom';
import {format, parseISO} from 'date-fns';

import Typography from 'components/Typography';
import Back from 'components/Back';
// import IconButton from 'components/IconButton';
import StatusBadge from 'components/StatusBadge';

import {Status} from 'types';
import {mapStatusColor, mapStatusText} from 'constants/mappings';

import styles from '../RequestDetails.module.scss';
import Button from 'components/Button';
import api from 'api';
import {useHistory} from 'react-router-dom';
import toast from 'react-hot-toast';
import {get} from 'lodash';
import useAuth from 'hooks/useAuth';
import DeleteModal from 'components/DeleteModal';
import {useState} from 'react';
import {origin} from '../RequestDetails';

interface Props {
  id: string;
  status: Status;
  created_at: string;
  app: string;
  completeDisabled: boolean;
  previous_id?: number;
  next_id?: number;
  rx_no: number;
  complete: () => void;
  is_resolved: boolean;
}

function Header({
  id,
  status,
  created_at,
  app,
  rx_no,
  is_resolved,
}: // completeDisabled,
// previous_id,
// next_id,
// complete,
Props) {
  const {currentUser} = useAuth();
  const [deleteModal, setDeleteModal] = useState(false);
  const datetime = parseISO(created_at);
  const {goBack} = useHistory();

  const isPrescriber = get(currentUser, 'role') === 'prescriber';

  const deleteOrder = async () => {
    try {
      await api.delete(`/requests/${id}/delete/`);
      goBack();
    } catch (error) {
      toast.error(get(error, 'response.data.error_message', 'Error'));
    }
  };

  return (
    <div className={styles.header}>
      <div>
        <div className={styles.title_wrapper}>
          <Back />
          <Typography className={styles.title} variant="h2">
            {origin[app as keyof typeof origin] === 'app' ? 'Request' : 'Order'}{' '}
            #M{id}
            {!!rx_no ? ` | RX #${rx_no}` : ''}
          </Typography>
          <StatusBadge
            color={
              status === 'call_doctor' && is_resolved
                ? 'green'
                : mapStatusColor[status]
            }
          >
            {mapStatusText[status]}
          </StatusBadge>
        </div>
        {isPrescriber ? (
          <Typography className={styles.subtitle} variant="h5">
            {format(datetime, 'MMMM d, yyyy')} at {format(datetime, 'h:mm aaa')}{' '}
          </Typography>
        ) : (
          <Typography className={styles.subtitle} variant="h5">
            {format(datetime, 'MMMM d, yyyy')}{' '}
            {/* at {format(datetime, 'h:mm aaa')}{' '} */}
            from{' '}
            {app.replace('_', ' ').slice(0, 1).toUpperCase() +
              app.replace('_', ' ').slice(1)}
          </Typography>
        )}
      </div>
      <div className={styles.buttons}>
        {app === 'dashboard' && (
          <Button
            onClick={() => setDeleteModal(true)}
            color="outlined"
            style={{
              color: '#ff3b24',
              borderColor: '#ff3b24',
              borderWidth: 1.5,
            }}
          >
            Delete order
          </Button>
        )}
        {/*{status !== 'completed' && (*/}
        {/*  <Button*/}
        {/*    className={styles.complete_btn}*/}
        {/*    color="green"*/}
        {/*    disabled={completeDisabled}*/}
        {/*    onClick={complete}*/}
        {/*  >*/}
        {/*    Mark transfers as complete*/}
        {/*  </Button>*/}
        {/*)}*/}
        {/* <IconButton
          icon="arrow_left"
          disabled={!previous_id}
          onClick={() => push(`/requests/${previous_id}`)}
        />
        <IconButton
          icon="arrow_right"
          disabled={!next_id}
          onClick={() => push(`/requests/${next_id}`)}
        /> */}
      </div>
      <DeleteModal
        openModal={deleteModal}
        setOpenModal={setDeleteModal}
        onDelete={deleteOrder}
        text="Are you sure, you want to delete this order?"
      />
    </div>
  );
}

export default Header;
