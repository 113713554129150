import api from 'api';
import {get} from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import toast from 'react-hot-toast';
import {QueryFunctionContext, useQuery} from 'react-query';
import {useRouteMatch} from 'react-router-dom';
import {BlogResponse} from 'types';

interface FormValues {
  title: string;
  content: string;
  image: string;
  slug: string;
  is_active: boolean;
}

async function getBlog({
  queryKey,
}: QueryFunctionContext): Promise<BlogResponse> {
  const [, slug] = queryKey;
  const {data} = await api.get<BlogResponse>(`/support/blogs/${slug}/`);
  return data as BlogResponse;
}

function useFormComponent() {
  const [image, setImage] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const {params} = useRouteMatch<{slug: string}>();

  const {data} = useQuery(['blog_details', params.slug], getBlog);

  const isInitialRender = useRef(0);

  const {
    control,
    handleSubmit,
    setValue: setFieldValue,

    formState: {errors},
  } = useForm<FormValues>();

  useEffect(() => {
    if (data && isInitialRender.current < 2) {
      isInitialRender.current++;

      setFieldValue('content', data?.content || '');
      setFieldValue('title', data?.title || '');
      setFieldValue('image', data?.image as any);
      setFieldValue('slug', data?.slug || '');
      setFieldValue('is_active', data?.is_active || false);
    }
  }, [data, setFieldValue]);

  const createHandler = (data: FormValues) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('slug', data.slug.toLowerCase().replace(/ /g, '-'));
    formData.append('content', data.content);
    formData.append('content_type', 'page');
    formData.append('is_active', data.is_active ? 'true' : 'false');
    if (image) {
      formData.append('image.file', image);
    }
    api
      .put(`/support/blogs/${params.slug}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        toast.success('Pages updated successfully');
        setLoading(false);
      })
      .catch((err) => {
        const errorObj = get(err, 'response.data', {});
        const errors = Object.keys(errorObj).map(
          (key) => key + ': ' + errorObj[key]
        );
        toast.error(errors.join('\n'));
        setLoading(false);
      });
  };

  const onChangeStatus = (status: boolean) => {
    setFieldValue('is_active', status);
  };

  return {
    image,
    setImage,
    control,
    errors,
    loading,
    data,
    onChangeStatus,
    createHandler: handleSubmit(createHandler),
    setFieldValue,
  };
}

export default useFormComponent;
