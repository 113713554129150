import Modal from '../Modal';
import styles from './Confirm.module.scss';
import Button from '../Button';
import Typography from '../Typography';

type Props = {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  onConfirm: () => void;
  text: string;
  component?: React.ReactNode;
  confirmText?: string;
  buttonType?: 'danger' | 'gray' | 'green' | 'primary';
};

const DeleteModal = ({
  openModal,
  setOpenModal,
  onConfirm,
  text,
  component,
  confirmText,
  buttonType = 'primary',
}: Props) => {
  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => setOpenModal(false)}
      onClose={() => setOpenModal(false)}
      className={styles.modal_wrapper}
    >
      <Typography variant="h2">Confirmation</Typography>
      <div className={styles.textWrapper}>
        <Typography className={styles.text} variant="p">
          {text}
        </Typography>
      </div>
      {component}
      <div className={styles.actionWrapper} style={{marginTop: 25}}>
        <Button onClick={onConfirm} color={buttonType}>
          {confirmText || 'Confirm'}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
