export const phoneFormat = (number: string) => {
  if (!number) return '';

  const format = '(000) 000-0000';
  let digits = number;

  digits = digits.replace('+1', '').replace(/\D/g, '');

  let formattedNumber = '';

  let digitIndex = 0;

  for (let i = 0; i < format.length; i++) {
    if (digitIndex >= digits.length) break;

    if (format[i] === '0') {
      formattedNumber += digits[digitIndex];
      digitIndex++;
    } else {
      formattedNumber += format[i];
    }
  }

  return formattedNumber;
};
