import {useEffect, useMemo, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {useQuery, QueryFunctionContext} from 'react-query';

import api from 'api';

import {Driver, ListResponse, SelectOption, Vehicle} from 'types';
import {getFullName} from 'helpers/user';
import {get} from 'lodash';

interface FormValue {
  nickname: string;
  model: string;
  email: string;
  license_plate_number: string;
  driver?: SelectOption;
  vehicle_type?: SelectOption;
}

async function getDrivers() {
  const {data} = await api.get<ListResponse<Driver>>('/drivers/');

  return data;
}

async function getVehicleById({queryKey}: QueryFunctionContext) {
  const [, vehicleId] = queryKey;

  if (!vehicleId) return undefined;

  const {data} = await api.get<Vehicle>(`/vehicles/${vehicleId}`);

  return data;
}

function useVehicleForm() {
  const {vehicleId} = useParams<{vehicleId?: string}>();
  const {push} = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    formState: {errors},
    reset,
  } = useForm<FormValue>();

  const {data: drivers} = useQuery('drivers', getDrivers);
  const {data: vehicle} = useQuery(['vehicle', vehicleId], getVehicleById);

  useEffect(
    function () {
      if (!vehicle) return;

      reset({
        ...vehicle,
        driver: vehicle.driver
          ? {
              value: vehicle.driver.id,
              label: getFullName(vehicle.driver),
            }
          : undefined,
        vehicle_type: vehicle.vehicle_type
          ? {
              value: vehicle.vehicle_type,
              label: vehicle.vehicle_type === 'car' ? 'Car' : 'Bike',
            }
          : {
              value: 'car',
              label: 'Car',
            },
      });
    },
    [vehicle, reset]
  );

  const options = useMemo(
    function () {
      if (!drivers) return [];

      return drivers.results
        .filter((driver) => !driver.is_assigned)
        .map((d) => ({
          value: d.id,
          label: d.full_name,
        }));
    },
    [drivers]
  );

  async function submit(formValue: FormValue) {
    setSubmitting(true);

    const data = {
      ...formValue,
      driver: formValue.driver?.value,
      vehicle_type: get(formValue, 'vehicle_type.value', 'car'),
    };

    if (vehicleId) {
      await api.put(`/vehicles/${vehicleId}/`, data);
    } else {
      await api.post('/vehicles/', data);
    }

    setSubmitting(false);
    push('/settings/vehicles');
  }

  return {
    vehicleId,
    submitting,
    errors,
    control,
    options,
    drivers,
    vehicle,
    register,
    handleSubmit: handleSubmit(submit),
  };
}

export default useVehicleForm;
