import {useCallback, useEffect, useMemo, useState} from 'react';
import {QueryFunctionContext, useQuery} from 'react-query';

import {DataItem, ListResponse, Transaction} from 'types';
import useSort from 'hooks/useSort';
import api from 'api';
import {format, parseISO} from 'date-fns';
import {get} from 'lodash';
import {useForm} from 'react-hook-form';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {formatDateString} from 'pages/Settings/Users/Users';
import {toast} from 'react-toastify';

async function getRequests({queryKey}: QueryFunctionContext<any[]>) {
  const [, page, status, creditType, date, key, startDate, endDate, pageSize] =
    queryKey;

  const {data} = await api.get<ListResponse<Transaction>>(
    '/payment/transactions',
    {
      params: {
        offset: +page * +pageSize,
        limit: +pageSize,
        transaction_origin: status ? status : undefined,
        service_name: creditType ? creditType.value : undefined,
        transaction_date: date,
        search: key ? key : undefined,
        transaction_date_after: startDate
          ? format(startDate, 'yyyy-MM-dd')
          : undefined,
        transaction_date_before: endDate
          ? format(endDate, 'yyyy-MM-dd')
          : undefined,
      },
    }
  );

  return data;
}

async function getRequestsGraph1() {
  const {data} = await api.get<ListResponse<Transaction>>(
    '/payment/transactions',
    {
      params: {
        limit: 1,
        transaction_origin: 'pos',
      },
    }
  );
  return data;
}
async function getRequestsGraph2() {
  const {data} = await api.get<ListResponse<Transaction>>(
    '/payment/transactions',
    {
      params: {
        limit: 1,
        transaction_origin: 'patient_app',
      },
    }
  );
  return data;
}
async function getRequestsGraph3() {
  const {data} = await api.get<ListResponse<Transaction>>(
    '/payment/transactions',
    {
      params: {
        limit: 1,
        transaction_origin: 'dashboard',
      },
    }
  );
  return data;
}
async function getRequestsGraph4() {
  const {data} = await api.get<ListResponse<Transaction>>(
    '/payment/transactions',
    {
      params: {
        limit: 1,
        transaction_origin: 'driver_app_cod',
      },
    }
  );
  return data;
}

const returnType = (type: string) => {
  if (type === 'patient_app') {
    return 'Patient App';
  }
  if (type === 'pos') {
    return 'POS';
  }
  if (type === 'dashboard') {
    return 'Dashboard';
  }
  if (type === 'driver_app_cod') {
    return 'Driver App COD';
  }
  if (type === 'payment_link') {
    return 'Payment Link';
  }
  return type;
};

function transformData(data: Transaction[]) {
  return data.map((item) => ({
    ...item,
    id: item.transaction_id,
    transaction_origin: returnType(item.transaction_origin),
    transaction_date: format(
      parseISO(item.transaction_date),
      'MM/dd/yy EEE hh:mm a'
    ),
    amount: '$' + item.amount,
    list_rx: `${item.list_rx.slice(0, 3).join(', ')} ${
      item.list_rx.length > 3 ? '...' : ''
    }`,
    list_orders: `${get(item, 'list_orders', []).slice(0, 3).join(', ')} ${
      item?.list_orders?.length > 3 ? '...' : ''
    }`,
  }));
}

type ListRx = {
  amount_due: string;
  drug_name: string;
  refill_no: string;
  rx_no: string;
  id: string;
};

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  birthday: string;
  patient_no: string;
  amount: string;
  card: string;
  list_rx: ListRx[];
};

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

function useRequests() {
  const history = useHistory();
  const {params} = useRouteMatch<{page: string | undefined}>();
  const {sortBy, sortOrder, onSort} = useSort('id', 'desc');
  const [pageSize, setPageSize] = useState(40);
  const [currentPage, setCurrentPage] = useState(parseInt(params.page || '1'));
  const [source] = useState('');
  const [chartVisible, setChartVisible] = useState(false);
  const [status, setStatus] = useState(
    sessionStorage.getItem('state') === 'all'
      ? ''
      : sessionStorage.getItem('transactions_state') || ''
  );
  const [key, setKeyWord] = useState(
    sessionStorage.getItem(`searchKey_transaction`) || ''
  );
  const [apiKeyWord, setApiKeyWord] = useState(
    sessionStorage.getItem(`searchKey_transaction`) || ''
  );

  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(new Date());

  const initialDate = sessionStorage.getItem('transactionDate')
    ? new Date(JSON.parse(sessionStorage.getItem('transactionDate') || ''))
    : undefined;

  const [date, setDate] = useState(initialDate);

  const [creditType, setCreditType] = useState<
    undefined | {value: string; label: string}
  >(
    sessionStorage.getItem('creditType')
      ? JSON.parse(sessionStorage.getItem('creditType') || '')
      : {label: 'Sale', value: 'CreditSale'}
  );

  const [visible, setVisible] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const [birthdate, setBirthdate] = useState('');

  const {data, isLoading} = useQuery(
    [
      'requests',
      String(currentPage - 1 || 0),
      status,
      creditType,
      date,
      apiKeyWord,
      startDate,
      endDate,
      String(pageSize),
    ],
    getRequests
  );

  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: {errors},
  } = useForm<FormValues>({
    defaultValues: {
      list_rx: [
        {
          id: '',
          rx_no: '',
          refill_no: '',
          drug_name: '',
          amount_due: '',
        },
      ],
    },
  });

  const {data: graph1} = useQuery(['graph1'], getRequestsGraph1, {
    enabled: chartVisible,
  });
  const {data: graph2} = useQuery(['graph2'], getRequestsGraph2, {
    enabled: chartVisible,
  });
  const {data: graph3} = useQuery(['graph3'], getRequestsGraph3, {
    enabled: chartVisible,
  });
  const {data: graph4} = useQuery(['graph4'], getRequestsGraph4, {
    enabled: chartVisible,
  });

  const onChangeData = (val: any) => {
    const [start, end] = val;
    setStartDate(start);
    setEndDate(end);

    // setDate(val);
    // sessionStorage.setItem('transactionDate', val);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setApiKeyWord(key);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [key]);

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params.page]);

  const tabs = useMemo(function () {
    return [
      {title: 'All', value: ''},
      {title: 'POS', value: 'pos'},
      {title: 'Patient App', value: 'patient_app'},
      {title: 'Payment Link', value: 'payment_link'},
      {title: 'Dashboard', value: 'dashboard'},
      {title: 'Driver App COD', value: 'driver_app_cod'},
    ];
  }, []);

  const handleNavigateRequest = useCallback(
    (row: DataItem) => {
      if (get(row, 'service_name', '') !== 'CreditAccountVerify') {
        history.push(`/transactions/${row.id}`);
      }
    },
    [history]
  );

  function handleDateChange(event: {target: {value: any}}) {
    const inputDate = event.target.value;
    const formattedDate = formatDateString(inputDate);
    setBirthdate(formattedDate);
    setValue('birthday', formattedDate);
  }

  const onSubmit = async (data: FormValues) => {
    setSubmitting(true);
    try {
      const birthday = data.birthday.split('/');
      await api.post('/payment/transactions/', {
        customer: {
          user: {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone: data.phone.replace(/\D/g, ''),
          },
          birthday: birthday[2] + '-' + birthday[0] + '-' + birthday[1],
          patient_no: data.patient_no,
        },
        amount: data.amount,
        masked_card_number: data.card,
        list_rx: data.list_rx,
      });
      setVisible(false);
      setSubmitting(false);
    } catch (error) {
      toast.error('Failed to add transaction');
      setSubmitting(false);
    }
  };

  return {
    data: data && !isLoading ? transformData(data.results) : [],
    count: data?.count || 0,
    totalPages:
      data?.count && data.limit ? Math.ceil(data.count / data.limit) : 0,
    dailyCount: data?.count || 0,
    totalAmount:
      (get(data, 'total_amount') === 'None'
        ? 0
        : get(data, 'total_amount', '0')) || 0,
    loading: isLoading,
    sortBy,
    sortOrder,
    tabs,
    status,
    keyWord: key,
    setKeyWord,
    onSort,
    setStatus,
    source,
    setDate,
    date,
    onChangeData,
    setCreditType,
    creditType,
    startDate,
    endDate,
    chartVisible,
    setChartVisible,
    chart: [
      {title: 'POS', value: graph1?.count || 0, color: '#E38627'},
      {title: 'Patient App', value: graph2?.count || 0, color: '#C13C37'},
      {title: 'Dashboard', value: graph3?.count || 0, color: '#6A2135'},
      {title: 'Driver App COD', value: graph4?.count || 0, color: '#0072b3'},
    ],
    pageSize,
    setPageSize,
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    errors,
    currentPage,
    setCurrentPage,
    handleNavigateRequest,
    handleDateChange,
    onSubmit,
    visible,
    setVisible,
    submitting,
    birthdate,
    history,
  };
}

export default useRequests;
